<template>
  <div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row my-4">
      <div class="div_left"
           v-if="(user[0] == 'ADMIN' || user[0] == 'DAF' ||user[0] == 'DCG')  && user[1] == 'FER'">
        <a class="btn btn-blue px-5"
           data-toggle="modal"
           ref="modal_button"
           data-target="#exampleModal">Ajouter un flux</a>
      </div>
    </div>
    <table class="table tablestyle_3 table-striped">
      <thead>
        <tr>
          <th style="width:12%;"></th>
          <template v-for="n in long">
            <th :key="n"
                style="width:12%;"
                class="text-center">{{ Number(annees) +Number(n)-1 }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-if="tresorerie.donnees">
          <td class="th-blue"
              style="width:12%;">Décomptes</td>
          <template v-for="(dec,dkey) in tresorerie.donnees.decompte">
            <td :key="dkey"
                class="text-right">{{ Math.round(dec).toLocaleString() }}</td>
          </template>
        </tr>
        <tr>
          <td class="th-blue">Dettes remboursement</td>
          <template v-for="(dette,dekey) in dettes">
            <td :key="dekey"
                class="text-right">{{ Math.round(dette).toLocaleString() }}</td>
          </template>
        </tr>
        <tr v-if="tresorerie.donnees">
          <td class="th-blue">Autres sorties</td>
          <template v-for="(sortie,sokey) in tresorerie.donnees.autres_sorties">
            <td :key="sokey"
                class="text-right">{{ Math.round(sortie).toLocaleString() }}</td>
          </template>
        </tr>
        <tr>
          <td class="tht-blue">Sorties</td>
          <template v-for="(sortie,skey) in sorties">
            <td :key="skey"
                class="text-right tht-blue">{{ Math.round(sortie).toLocaleString() }}</td>
          </template>
        </tr>
        <tr v-if="tresorerie.donnees">
          <td class="th-blue">Banques</td>
          <template v-for="(bank,bkey) in tresorerie.donnees.banque">
            <td :key="bkey"
                class="text-right">{{ Math.round(bank).toLocaleString() }}</td>
          </template>
        </tr>
        <tr v-if="tresorerie.donnees">
          <td class="th-blue">Caisses</td>
          <template v-for="(caisse,cakey) in tresorerie.donnees.caisse">
            <td :key="cakey"
                class="text-right">{{ Math.round(caisse).toLocaleString() }}</td>
          </template>
        </tr>
        <tr v-if="tresorerie.donnees">
          <td class="th-blue">Autres entrées</td>
          <template v-for="(entree,entkey) in tresorerie.donnees.autres_entrees">
            <td :key="entkey"
                class="text-right">{{ Math.round(entree).toLocaleString() }}</td>
          </template>
        </tr>
        <tr>
          <td class="tht-blue">Entrées</td>
          <template v-for="(entree,enkey) in entrees">
            <td :key="enkey"
                class="text-right tht-blue">{{ Math.round(entree).toLocaleString() }}</td>
          </template>
        </tr>
        <tr>
          <td class="tht-dark-blue">Solde de trésorerie</td>
          <template v-for="(solde,solkey) in solde_tresorerie">
            <td :key="solkey"
                class="text-right tht-dark-blue">{{ Math.round(solde).toLocaleString() }}</td>
          </template>
        </tr>
      </tbody>
    </table>
    <div class="row mt-4">
      <div class="col-9 mx-auto">
        <div style="width: 800px; margin: auto"
             v-if="loaded">
          <BarChart :chartdata="randomChart1"
                    :options="optionsChart1"/>
        </div>
      </div>
    </div>
    <!-- Modal relevé bancaire-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Ajouter un flux
                </div>
              </div>
            </div>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="btn-close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Source:</label>
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="form.source">
                  <option value="">----Choisissez une source----</option>
                  <option value="banque">Banque</option>
                  <option value="caisse">Caisse</option>
                  <option value="autres_entrees">Autres entrées</option>
                  <option value="autres_sorties">Autres Sorties</option>
                </select>
              </div>
              <div class="form-group"
                   v-if="form.source!='autres_sorties'">
                <label for="">Mouvement entrant:</label>
                <input class="form-control"
                       type="text"
                       v-model="mouvement_entrant"
                       @input="addSpace('mouvement_entrant')">
              </div>
              <div class="form-group"
                   v-else>
                <label for="">Mouvement sortant:</label>
                <input class="form-control"
                       type="text"
                       v-model="mouvement_sortant"
                       @input="addSpace('mouvement_sortant')">
              </div>
              <div class="form-group">
                <label for="">Date:</label>
                <input class="form-control"
                       type="date"
                       v-model="form.date">
              </div>
              <div class="form-group">
                <label for="">Observation:</label>
                <textarea name="" 
                          id="" 
                          class="form-control"
                          v-model="form.observation"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    @click="submit">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import BarChart from "@/components/shared/BarChart"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"

export default ({
  name:"Generale",
  props:["extYear"],
  components:{
    BarChart,
    Notif
  },
  data:()=>({
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    mouvement_entrant:"",
    mouvement_sortant:"",
    form:form({
      source:"",
      mouvement_entrant:0,
      mouvement_sortant:0,
      date:"",
      observation:"",
    }),
    long:0,
    loaded:false,
    annees:0,
    dettes:[],
    sorties:[],
    entrees:[],
    solde_tresorerie:[],
    randomChart1: {
      labels: [new Date().getFullYear()],
      datasets: [
        {
          label: "Entrées",
          data: [30, 20, 40, 50, 5],
          backgroundColor: ["#0097a9"]
        },
        {
          label: "Sorties",
          data: [30, 20, 40, 50, 5],
          backgroundColor: ["#016677"]
        },
      ]
    },
    optionsChart1: {
      responsive: true,
      maintainAspectRatio: false
    },
  }),
  watch:{
    tresorerie(){
      if (this.tresorerie.donnees) {
        this.long = this.tresorerie.donnees.banque.length
        this.annees= this.extYear.annee_debut.split("-")
        this.annees= this.annees[0]
        this.getEngagementFinancier(this.year)

        console.log(this.annees)
      }
    },
    engagementFinancier(){
      if (this.engagementFinancier) {
        this.dettes=[]
        for (let index = 0; index < this.long; index++) {
          this.dettes.push(0)
          this.engagementFinancier.engagement_financier.forEach(engagement => {
            var annee_engagement=0
            annee_engagement = engagement.date_debut.split("-")
            annee_engagement = annee_engagement[0]
            if ((Number(this.annees)+index) == annee_engagement) {
              this.dettes[index] += engagement.montant_rembourse_interet + engagement.montant_rembourse_principal  
            }
          })          
        }
        this.sorties=[]
        this.entrees=[]
        this.solde_tresorerie=[]
        this.randomChart1.labels=[]
        this.randomChart1.datasets[1].data=[]
        this.randomChart1.datasets[1].backgroundColor=[]
        this.randomChart1.datasets[0].data=[]
        this.randomChart1.datasets[0].backgroundColor=[]
        // console.log(this.randomChart1)
        for (let index = 0; index < this.long; index++) {
          this.sorties.push(this.dettes[index] + this.tresorerie.donnees.decompte[index] + this.tresorerie.donnees.autres_sorties[index])
          this.entrees.push(this.tresorerie.donnees.banque[index] + this.tresorerie.donnees.caisse[index] + this.tresorerie.donnees.autres_entrees[index])
          this.solde_tresorerie.push(this.entrees[index]-this.sorties[index])
          this.randomChart1.labels.push(Number(this.annees)+index)
          this.randomChart1.datasets[0].data.push(this.entrees[index])
          this.randomChart1.datasets[0].backgroundColor.push("#0097a9")
          this.randomChart1.datasets[1].data.push(this.sorties[index])
          this.randomChart1.datasets[1].backgroundColor.push("#016677")
        }
        console.log(this.randomChart1)
        setTimeout(
          function() {
            this.loaded=true
          }.bind(this),
          1000
        )
         
      }
    },
    msgSuccessTresorerie(){
      if (this.msgSuccessTresorerie) {
        this.setTresorerie("")
        this.notif.message = "Flux enregistré"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        this.mouvement_entrant=0
        this.mouvement_sortant=0
        this.form=form({
          source:"",
          mouvement_entrant:0,
          mouvement_sortant:0,
          date:"",
          observation:"",
        })
        setTimeout(
          function() {
            this.notif.activated = ""
            this.loadTresorerie(this.year)
            this.setMsgSuccessTresorerie("")
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        )
      }
    },
  },
  created(){
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.year.annee_debut= this.extYear.annee_debut
    this.year.annee_fin= this.extYear.annee_fin
    this.loadTresorerie(this.year)
  },
  computed: {
    ...mapGetters(["tresorerie","msgFailTresorerie","engagementFinancier","msgSuccessTresorerie"])
  },
  methods: {
    ...mapActions(["loadTresorerie","getEngagementFinancier","postTresorerie"]),
    ...mapMutations(["setTresorerie","setMsgSuccessTresorerie","setMsgFailTresorerie","setEngagementFinancier","setReponseError"]),
    changeYear() {
      this.loaded= false
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      this.setTresorerie("")
      this.setEngagementFinancier("")  
      setTimeout(
        function() {
          this.loadTresorerie(this.year)          
        }.bind(this),
        1000
      )
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    submit(){
      this.loaded=false
      this.postTresorerie(this.form.data)
    }
  }
})
</script>
