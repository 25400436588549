<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-8 mx-auto">
        <form class="form form-inline">
          <!-- <div class="form-group">
            <label for="">Date:</label>
            <input type="date" 
                   class="form-control ml-2"
                   v-model="date_jour"/>
          </div> -->
          <div class="form-group mx-4">
            <label for="">Nombre de jours de proximité:</label>
            <input type="number" 
                   class="form-control ml-2"
                   v-model="nb_jour"/>
          </div>
          <div class="form-group">
            <button type="button" 
                    class="btn btn-info"
                    @click="launchRequest()">Rechercher</button>
          </div>
        </form>
      </div>
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>

    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  :filename="'Situation_des_engagements_financiers_'+dateJour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-3">
          <div class="col-3 ml-4">
            <img src="../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ dateJour }}
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Situation des engagements financiers <br>
                du {{ dateJour }} au 
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <div class="row mt-3">
            <div class="col-md-6 text-center mx-auto"
                 style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
              Engagements Financiers
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-5 text-center mx-auto"
                 style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
              Remboursement des emprunts <br/>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5 text-center mx-auto"
                 style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
              Garanties des échéances <br/>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row mt-3">
            <div class="col-md-1 mb-4 ml-5"
                 style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
            </div>
            <div class="col-md-4 text-center"
                 style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
              Provision constituée pour la couverture des remboursements <br/>
              {{Math.round(total.domiciliation_provision.situation_a_date).toLocaleString()}}
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-1 mb-4 ml-2"
                 style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
            </div>
            <div class="col-md-4 text-center"
                 style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
              Reserves constituées <br/>
              {{Math.round(total.sequestre.situation_a_date).toLocaleString()}}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-1 mb-3 ml-5"
                 style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
            </div>
            <div class="col-md-4 text-center"
                 style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
              Echéances à couvrir <br/>
              {{Math.round(echeance_a_couvrir).toLocaleString()}}
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-1 mb-3 ml-2"
                 style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
            </div>
            <div class="col-md-4 text-center"
                 style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
              Garanties nécessaires <br/>
              {{Math.round(garanti_necessaire).toLocaleString()}}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-1 mb-4 ml-5"
                 style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
            </div>
            <div class="col-md-4 text-center"
                 :class="coloresultA" 
                 style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
              Provision à constituer <br/>
              <span :class="coloresultA">{{ Math.round((total.domiciliation_provision.situation_a_date) 
                -echeance_a_couvrir).toLocaleString() }}</span>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-1 mb-4 ml-2"
                 style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
            </div>
            <div class="col-md-4 text-center"
                 style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
              Réserve à constituer <br/>
              <span :class="coloresultB">{{ Math.round((total.sequestre.situation_a_date) 
                - garanti_necessaire).toLocaleString()}}</span>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;"
             v-if="afficheEcheance">
          <div class="row mt-5 justify-content-center"
               v-for="(dette,detkey) in listDette"
               :key="detkey">

            <div class="col-md-4"
                 v-for="(echeance,echkey) in dette.contenu"
                 :key="echkey">
              <div class="card">
                <div class="card-header header-blue">
                  <h6 class="text-center ">{{echeance.echeance.banque}} <br> {{ Math.round(Number(echeance.echeance.montant_pret)).toLocaleString() }} FCFA</h6>
                </div>
                <div class="card-body">
                  <div class="row mb-3">
                    <strong>Date de l'échéance &nbsp;:&nbsp;</strong>{{ echeance.echeance.date_echeance_proche }}
                  </div>
                  <div class="row mb-3">
                    <strong>Provisions disponibles &nbsp;:&nbsp;</strong>{{ echeance.echeance.situation_a_date.toLocaleString() }} FCFA
                  </div>
                  <div class="progress row"
                       style="height:4vh;">
                    <div class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                         role="progressbar"
                         :style="'width:' + echeance.couverture+ '%'"
                         :aria-valuenow="echeance.couverture "
                         aria-valuemin="0"
                         aria-valuemax="100">
                      {{ echeance.couverture }}%
                    </div>
                  </div>
                  <div class="row mb-3">
                    <strong>Montant de l'échéance &nbsp;:&nbsp;</strong> {{ Math.round(Number(echeance.echeance.montant_echeance)).toLocaleString() }} FCFA
                  </div>
                  <div class="row mb-3">
                    <strong>Ecart &nbsp;:&nbsp;</strong>  
                    <span v-if="echeance.echeance.ecart<0"
                          class="bad-news">{{ echeance.echeance.ecart.toLocaleString() }}&nbsp;</span>
                    <span v-else
                          class="good-news">{{ echeance.echeance.ecart.toLocaleString() }}&nbsp;</span> FCFA
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <div class="row ml-2">
            <h3>Commentaire:</h3>
          </div>
          <div class="row mt-3">
            <div class="col-md-9 mx-auto text-capitalize"
                 style="border: 1px solid gray;">
              {{ commentaire }}
            </div>
          </div>
        </div>
        
        <!-- <div style="width: 95%;margin: auto;">
          <table class="table tablestyle_3 table-striped my-4">
            <thead>
              <tr>
                <th class="tht-blue">Libellé</th>
                <th class="tht-blue">Montant</th>
              </tr>
            </thead>
            <! -- COMPTES TRAVAUX & FONCTIONNEMENT  -- >
            <tbody>
              <tr>  
                <td scope="row"
                    colspan="2"
                    class="tht-dark-blue text-center">REMBOURSEMENTS DES EMPRUNTS</td>    
              </tr>
              <tr>  
                <td scope="row">Provision constituée pour la couverture des remboursements</td>
                <td class="text-right">{{Math.round(total.domiciliation_provision.mouvements_entrants - total.domiciliation_provision.mouvements_sortants).toLocaleString()}}</td>    
              </tr>
              <tr>  
                <td scope="row">Echéances à couvrir</td>
                <td class="text-right">{{Math.round(echeance_a_couvrir).toLocaleString()}}</td>    
              </tr>
              <tr>  
                <td scope="row">Provision à constituer</td>
                <td class="text-right good-news"
                    v-if="(total.domiciliation_provision.mouvements_entrants - total.domiciliation_provision.mouvements_sortants) 
                      -echeance_a_couvrir > 0">{{ 
                  Math.round((total.domiciliation_provision.mouvements_entrants - total.domiciliation_provision.mouvements_sortants) 
                    -echeance_a_couvrir).toLocaleString() 
                }}</td>
                <td class="text-right bad-news"
                    v-else>{{ 
                      Math.round((total.domiciliation_provision.mouvements_entrants - total.domiciliation_provision.mouvements_sortants) 
                        -echeance_a_couvrir).toLocaleString() 
                    }}</td>    
              </tr>
              <tr>  
                <td scope="row"
                    colspan="2"
                    class="tht-dark-blue text-center">GARANTIES DES ECHEANCES</td>    
              </tr>
              <tr>  
                <td scope="row">Réserves constituées</td>
                <td class="text-right">{{Math.round(total.sequestre.mouvements_entrants - total.sequestre.mouvements_sortants).toLocaleString()}}</td>    
              </tr>
              <tr>  
                <td scope="row">Garanties nécessaire</td>
                <td class="text-right">{{Math.round(garanti_necessaire).toLocaleString()}}</td>    
              </tr>
              <tr>  
                <td scope="row">Réserve à constituer</td>
                <td class="text-right good-news"
                    v-if="(total.sequestre.mouvements_entrants - total.sequestre.mouvements_sortants) 
                      - garanti_necessaire >0">{{ 
                  Math.round((total.sequestre.mouvements_entrants - total.sequestre.mouvements_sortants) 
                    - garanti_necessaire).toLocaleString() 
                }}</td>
                <td class="text-right bad-news"
                    v-else>{{ 
                      Math.round((total.sequestre.mouvements_entrants - total.sequestre.mouvements_sortants) 
                        - garanti_necessaire).toLocaleString() 
                    }}</td>    
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->

    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mt-3">
      <div class="col-md-6 text-center mx-auto"
           style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
        Engagements Financiers
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-5 text-center mx-auto"
           style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
        Remboursement des emprunts <br/>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5 text-center mx-auto"
           style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
        Garanties des échéances <br/>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="row mt-3">
      <div class="col-md-1 mb-4 ml-5"
           style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
      </div>
      <div class="col-md-4 text-center"
           style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
        Provision constituée pour la couverture des remboursements <br/>
        {{Math.round(total.domiciliation_provision.situation_a_date).toLocaleString()}}
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-1 mb-4 ml-2"
           style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
      </div>
      <div class="col-md-4 text-center"
           style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
        Reserves constituées <br/>
        {{Math.round(total.sequestre.situation_a_date).toLocaleString()}}
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-1 mb-3 ml-5"
           style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
      </div>
      <div class="col-md-4 text-center echeance_couvrir"
           style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;"
           @click="toggleEcheance()">
        Echéances à couvrir <br/>
        {{Math.round(echeance_a_couvrir).toLocaleString()}}
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-1 mb-3 ml-2"
           style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
      </div>
      <div class="col-md-4 text-center"
           style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
        Garanties nécessaires <br/>
        {{Math.round(garanti_necessaire).toLocaleString()}}
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-1 mb-4 ml-5"
           style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
      </div>
      <div class="col-md-4 text-center"
           :class="coloresultA" 
           style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
        Provision à constituer <br/>
        <span :class="coloresultA">{{ Math.round(total.domiciliation_provision.situation_a_date - echeance_a_couvrir).toLocaleString() }}</span>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-1 mb-4 ml-2"
           style="border-left: 2px solid rgb(86, 97, 109);border-bottom: 2px solid rgb(86, 97, 109);">
      </div>
      <div class="col-md-4 text-center"
           style="background-color: rgb(86, 97, 109); color: white;border-radius: 8px;">
        Réserve à constituer <br/>
        <span :class="coloresultB">{{ Math.round(total.sequestre.situation_a_date - garanti_necessaire).toLocaleString()}}</span>
      </div>
    </div>
    <template v-if="afficheEcheance">
      <div class="row mt-3 justify-content-center"
           v-for="(dette,detkey) in listDette"
           :key="detkey">

        <div class="col-md-4"
             v-for="(echeance,echkey) in dette.contenu"
             :key="echkey">
          <div class="card">
            <div class="card-header header-blue">
              <h6 class="text-center ">{{echeance.echeance.banque}} <br> {{ Math.round(Number(echeance.echeance.montant_pret)).toLocaleString() }} FCFA</h6>
            </div>
            <div class="card-body">
              <div class="row mb-3">
                <strong>Date de l'échéance &nbsp;:&nbsp;</strong>{{ echeance.echeance.date_echeance_proche }}
              </div>
              <div class="row mb-3">
                <strong>Provisions disponibles &nbsp;:&nbsp;</strong>{{ echeance.echeance.situation_a_date.toLocaleString() }} FCFA
              </div>
              <div class="progress row"
                   style="height:4vh;">
                <div class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                     role="progressbar"
                     :style="'width:' + echeance.couverture+ '%'"
                     :aria-valuenow="echeance.couverture "
                     aria-valuemin="0"
                     aria-valuemax="100">
                  {{ echeance.couverture }}%
                </div>
              </div>
              <div class="row mb-3">
                <strong>Montant de l'échéance &nbsp;:&nbsp;</strong> {{ Math.round(Number(echeance.echeance.montant_echeance)).toLocaleString() }} FCFA
              </div>
              <div class="row mb-3">
                <strong>Ecart &nbsp;:&nbsp;</strong>  
                <span v-if="echeance.echeance.ecart<0"
                      class="bad-news">{{ echeance.echeance.ecart.toLocaleString() }}&nbsp;</span>
                <span v-else
                      class="good-news">{{ echeance.echeance.ecart.toLocaleString() }}&nbsp;</span> FCFA
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
    <div class="row mt-3">
      <div class="form-group col-md-5 mx-auto">
        <label for="">Commentaire:</label>
        <textarea class="form-control" 
                  name="" 
                  id="" 
                  rows="5"
                  placeholder="Votre message"
                  v-model="commentaire"></textarea>
      </div>
    </div>
    <!-- <table class="table tablestyle_3 table-striped my-4">
      <thead>
        <tr>
          <th class="th-blue">Libellé</th>
          <th class="th-blue">Montant</th>
        </tr>
      </thead>
      <! -- COMPTES TRAVAUX & FONCTIONNEMENT  -- >
      <tbody>
        <tr>  
          <td scope="row"
              colspan="2"
              class="tht-dark-blue text-center">REMBOURSEMENTS DES EMPRUNTS</td>    
        </tr>
        <tr>  
          <td scope="row">Provision constituée pour la couverture des remboursements</td>
          <td class="text-right">{{Math.round(total.domiciliation_provision.mouvements_entrants - total.domiciliation_provision.mouvements_sortants).toLocaleString()}}</td>    
        </tr>
        <tr>  
          <td scope="row">Echéances à couvrir</td>
          <td class="text-right">{{Math.round(echeance_a_couvrir).toLocaleString()}}</td>    
        </tr>
        <tr>  
          <td scope="row">Provision à constituer</td>
          <td class="text-right"
              :class="coloresultA">
            {{ Math.round((total.domiciliation_provision.mouvements_entrants - total.domiciliation_provision.mouvements_sortants) 
              - echeance_a_couvrir).toLocaleString() }}
          </td>
        </tr>
        <tr>  
          <td scope="row"
              colspan="2"
              class="tht-dark-blue text-center">GARANTIES DES ECHEANCES</td>    
        </tr>
        <tr>  
          <td scope="row">Réserves constituées</td>
          <td class="text-right">{{Math.round(total.sequestre.mouvements_entrants - total.sequestre.mouvements_sortants).toLocaleString()}}</td>    
        </tr>
        <tr>  
          <td scope="row">Garanties nécessaire</td>
          <td class="text-right">{{Math.round(garanti_necessaire).toLocaleString()}}</td>    
        </tr>
        <tr>  
          <td scope="row">Réserve à constituer</td>
          <td :class="coloresultB"
              class="text-right">
            {{ Math.round((total.sequestre.mouvements_entrants - total.sequestre.mouvements_sortants) 
              - garanti_necessaire).toLocaleString() }}
          </td>    
        </tr>
      </tbody>
      
    </table> -->
  </div>
</template>
<style>
i.flaticon-add{
  font-size: 0.6em;
}
.bad-news{
  color:#ff0000;
}
.good-news{
  color:#24b13c;
}
.echeance_couvrir{
  cursor: pointer;
}
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import VueHtml2pdf from 'vue-html2pdf'

export default ({
  name:"Engagements",
  props:["extYear","extBase","dateJour"],
  components: {
    Notif,
    VueHtml2pdf
  },
  data: ()=>({
    dateJour: "",
    nb_jour:90,
    form:[],
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    nbRowDette:1,
    listDette:[],
    afficheEcheance:false,
    loaded:false,
    commentaire:'',
    year:{
      annee_debut:"",
      annee_fin:"",
      periode:"mois"
    },
    coloresultA:"",
    coloresultB:"",
    echeance_a_couvrir:0,
    garanti_necessaire:0,
    total:{
      travaux:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      fonctionnement_investissements:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      domiciliation_provision:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      sequestre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      autre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      total:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
    },
  }),
  watch:{
    tresorerieSiege(){
      if (this.tresorerieSiege) {
        this.form=[]
        this.total={
          travaux:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          fonctionnement_investissements:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          domiciliation_provision:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          sequestre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          autre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          total:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
        }
        // Création des formualaires par ligne
        this.tresorerieSiege.donnees.forEach(siege => {
          // calcul des totaux
          
          if (siege.groupe=='COMPTES_PROVISION') {
            this.total.domiciliation_provision.situation_anterieure+=siege.situation_anterieure
            this.total.domiciliation_provision.mouvements_entrants+=siege.mouvements_entrants
            this.total.domiciliation_provision.mouvements_sortants+=siege.mouvements_sortants
            this.total.domiciliation_provision.situation_a_date+=siege.situation_a_date
            console.log("provision")
            console.log(siege.situation_a_date)
          }
          if (siege.groupe=='COMPTES_RESERVE') {
            this.total.sequestre.situation_anterieure+=siege.situation_anterieure
            this.total.sequestre.mouvements_entrants+=siege.mouvements_entrants
            this.total.sequestre.mouvements_sortants+=siege.mouvements_sortants
            this.total.sequestre.situation_a_date+=siege.situation_a_date
            console.log("reserve")
            console.log(siege.situation_a_date)

          }
          
        })
        console.log(this.total)
        this.loadPaiementProche({date_debut:this.dateJour,nb_jour:this.nb_jour,base:this.extBase})
      }
    },
    dateJour(){
      if (this.dateJour) {
        this.launchRequest()
      }
    },
    paiementProche(){
      if(this.paiementProche){
        this.echeance_a_couvrir=0
        this.garanti_necessaire= 0
        if (this.paiementProche.donnees) {
          this.paiementProche.donnees.forEach(paiement => {
            this.echeance_a_couvrir+=Number(paiement.montant_echeance)
            this.garanti_necessaire+=Number(paiement.garantie_necessaire)
          })
        }
        if ((this.paiementProche.donnees.length % 3) != 0) {
          this.nbRowDette = Math.round(Number(this.paiementProche.donnees.length/3))
          this.nbRowDette += 1
        } else {
          this.nbRowDette = Math.round(Number(this.paiementProche.donnees.length/3))
        }

        this.listDette=[]

        for (let index = 0; index < this.nbRowDette; index++) {
          this.listDette.push({nroligne:index+1,contenu:[]})
          for (let pointer = (index*3); pointer < ((this.listDette[index].nroligne)*3); pointer++) {
            if (pointer < this.paiementProche.donnees.length ) {
              this.listDette[index].contenu.push({echeance:this.paiementProche.donnees[pointer],ecart:0,couverture:0,provision_dispo:0})
            }
          }
        }

        // var provision = this.total.domiciliation_provision.situation_a_date

        this.listDette.forEach(dette => {
          var old_echeance=""
          dette.contenu.forEach(echeance => {
            if (old_echeance!="") {
              if (
                (old_echeance.echeance.situation_a_date == echeance.echeance.situation_a_date) && 
                (old_echeance.echeance.compte_comptable_provision == echeance.echeance.compte_comptable_provision)
              ) {
                echeance.provision_dispo = old_echeance.echeance.situation_a_date - old_echeance.echeance.montant_echeance 
              }
            }else{
              echeance.provision_dispo = echeance.echeance.situation_a_date
            }
            // if (provision > Math.round(Number(echeance.echeance.montant_echeance))) {
            //   echeance.ecart=0
            //   echeance.couverture=100
            // }else{
            // echeance.ecart = provision <= 0 ? - Math.round(Number(echeance.echeance.montant_echeance)) : provision - Math.round(Number(echeance.echeance.montant_echeance))
            echeance.couverture = Math.round((echeance.echeance.situation_a_date*100)/echeance.echeance.montant_echeance)
            // // }
            // provision-=Math.round(Number(echeance.echeance.montant_echeance))
            old_echeance=echeance
            console.log(old_echeance)
          })
        })

        console.log(this.listDette)
        if (this.total.sequestre.situation_a_date < this.garanti_necessaire) {
          this.coloresultB="bad-news"
        }else{
          this.coloresultB="good-news"
        }
        if (this.total.domiciliation_provision.situation_a_date < this.echeance_a_couvrir) {
          this.coloresultA="bad-news"
        }else{
          this.coloresultA="good-news"
        }
        this.loaded=true
      }
    }
  },
  created(){
    var jour = new Date()
    this.dateJour = jour.getFullYear()+"-"
    if (jour.getMonth() < 9) {
      this.dateJour +="0"
    }
    this.dateJour+=(jour.getMonth()+1)+"-"
    if (jour.getDate() < 10) {
      this.dateJour +="0" 
    }
    this.dateJour+=jour.getDate()
    this.loadTresorerieSiege({date:this.dateJour,base:this.extBase})
    this.year.annee_debut = this.extYear.annee_debut
    this.year.annee_fin = this.extYear.annee_fin
    var fin_annee=this.extYear.annee_fin.split("-")
    var debut_annee=this.extYear.annee_debut.split("-")
    if (fin_annee[0]==debut_annee[0]) {
      this.year.periode="mois"
    }else{
      this.year.periode="annee"
    }
  },
  computed:{
    ...mapGetters(["tresorerieSiege","msgFailTresorerieSiege","msgFailPaiementpaiementProche","paiementProche"])
  },
  methods:{
    ...mapActions(["loadTresorerieSiege","loadPaiementProche"]),
    ...mapMutations(["setTresorerieSiege","setMsgFailTresorerieSiege","setMsgFailPaiementProche","setPaiementProche",]),
    launchRequest(){
      this.loadTresorerieSiege({date:this.dateJour,base:this.extBase})
      
      // this.loadPaiementProche({date_debut:this.date_jour,nb_jour:this.nb_jour,base:this.extBase})
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    toggleEcheance(){
      if (this.afficheEcheance) {
        this.afficheEcheance=false
      }else{
        this.afficheEcheance=true
      }
    }
  }
})
</script>
