<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-2">
        <button class="btn btn-blue op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#exampleModal"> Importer trésorerie journalière</button>
      </div>
      <!-- <div class="col-md-5 mx-auto">
        <form class="form form-inline">
          <div class="form-group">
            <label for="">Date:</label>
            <input type="date" 
                   class="form-control ml-2"
                   v-model="dateJour"/>
          </div>
          <div class="form-group ml-3">
            <button type="button"
                    class="btn btn-info"
                    @click="launchRequest()">
              Rechercher
            </button>
          </div>
        </form>
      </div> -->
      <div class="col-md-3">
        <form class="form-inline">
          <div class="form-group">
            <label for="">Type de trésorerie</label>
            <select class="form-control mx-2" 
                    name="" 
                    id=""
                    v-model="type_tresorerie">
              <option value="tout">Générale</option>
              <option value="banque">Banque</option>
              <option value="caisse">Caisse</option>
            </select>
          </div>
        </form>
      </div>
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
      
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1300"
                  :filename="'Situation_de_trésorerie_journalière_'+extBase+'_'+dateJour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-3">
          <div class="col-3 ml-4">
            <img src="../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ dateJour }}
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Situation de trésorerie journalière ({{ extBase }})
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <table class="table tablestyle_3 table-striped my-4">
            <thead>
              <tr>
                <th class="tht-blue">Intitulé du compte (FER)</th>
                <th class="tht-blue text-right">Situation antérieure</th>
                <th class="tht-blue text-right">Mouvements entrants</th>
                <th class="tht-blue text-right">Mouvements sortants</th>
                <th class="tht-blue text-right">Situation à date</th>
                <th class="tht-blue">Observations</th>
              </tr>
            </thead>
            <!-- TRESORERIE SIEGE -->
            <tbody v-if="inBase=='CONSOLIDE'">
              <tr>
                <td scope="row"
                    colspan="7"
                    class="tht-dark-blue text-center">TRESORERIE SIEGE</td>
              </tr>
            </tbody>
            <!-- COMPTES TRAVAUX ROUTIERS  -->
            <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
              <template v-for="(siege,sikey) in tresorerieSiege.donnees">
                <tr :key="sikey"
                    v-if="siege.groupe=='COMPTES_TRAVAUX_ROUTIERS'">  
                  <td scope="row">{{ siege.intitule_compte }}</td>
                  <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
                  
                  <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
                  <td>{{ siege.observation }}</td>
                </tr>
              </template>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse'">
              <tr>
                <td scope="row"
                    :class="color">COMPTES TRAVAUX ROUTIERS</td>
                <td class="text-right"
                    :class="color">{{ total.travaux.situation_anterieure.toLocaleString() }}</td>
                <td class="text-right"
                    :class="color">{{ total.travaux.mouvements_entrants.toLocaleString() }}</td>
                <td class="text-right"
                    :class="color">{{ total.travaux.mouvements_sortants.toLocaleString() }}</td>
                <td class="text-right"
                    :class="color">{{ total.travaux.situation_a_date.toLocaleString() }}</td>
                <td :class="color"></td>
              </tr>
            </tbody>

            <!-- COMPTES FONCTIONNEMENT & INVESTISSEMENTS  -->
            <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
              <template v-for="(siege,sikey) in tresorerieSiege.donnees">
                <tr :key="sikey"
                    v-if="siege.groupe=='COMPTES_FONCTIONNEMENT_INVESTISSEMENTS'">  
                  <td scope="row">{{ siege.intitule_compte }}</td>
                  <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
                  
                  <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
                  <td>{{ siege.observation }}</td>
                </tr>
              </template>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase!='CONSOLIDE')">
              <tr>
                <td scope="row"
                    class="tht-dark-blue">COMPTES FONCTIONNEMENT & INVESTISSEMENTS</td>
                <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.situation_a_date.toLocaleString() }}</td>
                <td class="tht-dark-blue"></td>
              </tr>
            </tbody>

            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="">COMPTES FONCTIONNEMENT & INVESTISSEMENTS</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[0].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[0].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[0].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[0].situation_a_date.toLocaleString() }}</td>
                <td class=""></td>
              </tr>
            </tbody>
          
            <!-- COMPTES PROVISIONS  -->
            <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
              <template v-for="(siege,sikey) in tresorerieSiege.donnees">
                <tr :key="sikey"
                    v-if="siege.groupe=='COMPTES_PROVISION'">  
                  <td scope="row">{{ siege.intitule_compte }}</td>
                  <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
                  <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
                  <td>{{ siege.observation }}</td>
                </tr>
              </template>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
              <tr>
                <td scope="row"
                    class="tht-dark-blue">COMPTES PROVISIONS</td>
                <td class="tht-dark-blue text-right">{{ total.domiciliation_provision.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.domiciliation_provision.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.domiciliation_provision.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.domiciliation_provision.situation_a_date.toLocaleString() }}</td>
                <td class="tht-dark-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="">COMPTES PROVISIONS</td>
                <td class=" text-right">{{ total.domiciliation_provision[0].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[0].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[0].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[0].situation_a_date.toLocaleString() }}</td>
                <td class=""></td>
              </tr>
            </tbody>
            <!-- COMPTES SEQUESTRES  --> 
            <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
              <template v-for="(siege,sikey) in tresorerieSiege.donnees">
                <tr :key="sikey"
                    v-if="siege.groupe=='COMPTES_RESERVE'">  
                  <td scope="row">{{ siege.intitule_compte }}</td>
                  <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
                  <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
                  <td>{{ siege.observation }}</td>
                </tr>
              </template>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
              <tr>
                <td scope="row"
                    class="tht-dark-blue">COMPTES SEQUESTRES</td>
                <td class="tht-dark-blue text-right">{{ total.sequestre.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.sequestre.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.sequestre.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.sequestre.situation_a_date.toLocaleString() }}</td>
                <td class="tht-dark-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="">COMPTES SEQUESTRES</td>
                <td class=" text-right">{{ total.sequestre[0].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[0].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[0].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[0].situation_a_date.toLocaleString() }}</td>
                <td class=""></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && extBase!='CONSOLIDE'">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL BANQUES</td>
                <td class="tht-blue text-right">{{ total.totalBanque.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque.situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL BANQUES</td>
                <td class="tht-blue text-right">{{ total.totalBanque[0].situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[0].mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[0].mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[0].situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <!-- AUTRES  --> 
            <tbody v-if="type_tresorerie!='banque' && inBase!='CONSOLIDE'">
              <template v-for="(siege,sikey) in tresorerieSiege.donnees">
                <tr :key="sikey"
                    v-if="siege.groupe=='AUTRES'">  
                  <td scope="row">{{ siege.intitule_compte }}</td>
                  <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
                  <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
                  <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
                  <td>{{ siege.observation }}</td>
                </tr>
              </template>
            </tbody>
            <tbody v-if="type_tresorerie!='banque' && inBase!='CONSOLIDE'">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL CAISSES</td>
                <td class="tht-blue text-right">{{ total.autre.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre.situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL CAISSES</td>
                <td class="tht-blue text-right">{{ total.autre[0].situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[0].mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[0].mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[0].situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL GENERALE SIEGE</td>
                <td class="tht-blue text-right">{{ (total.autre[0].situation_anterieure+total.totalBanque[0].situation_anterieure).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[0].mouvements_entrants + total.totalBanque[0].mouvements_entrants).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[0].mouvements_sortants + total.totalBanque[0].mouvements_sortants).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[0].situation_a_date + total.totalBanque[0].situation_a_date).toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <!-- AFFICHAGE PEAGE -->
            <tbody v-if="inBase=='CONSOLIDE'">
              <tr>
                <td scope="row"
                    colspan="7"
                    class="tht-dark-blue text-center">TRESORERIE PEAGE</td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="">COMPTES FONCTIONNEMENT & INVESTISSEMENTS (PEAGE)</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[1].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[1].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[1].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[1].situation_a_date.toLocaleString() }}</td>
                <td class=""></td>
              </tr>
            </tbody>

            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="">COMPTES PROVISIONS (PEAGE)</td>
                <td class=" text-right">{{ total.domiciliation_provision[1].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[1].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[1].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[1].situation_a_date.toLocaleString() }}</td>
                <td class=""></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse'&& (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="">COMPTES SEQUESTRES (PEAGE)</td>
                <td class=" text-right">{{ total.sequestre[1].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[1].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[1].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[1].situation_a_date.toLocaleString() }}</td>
                <td class=""></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL BANQUES (PEAGE)</td>
                <td class="tht-blue text-right">{{ total.totalBanque[1].situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[1].mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[1].mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[1].situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL CAISSES (PEAGE)</td>
                <td class="tht-blue text-right">{{ total.autre[1].situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[1].mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[1].mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[1].situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL GENERALE PEAGE</td>
                <td class="tht-blue text-right">{{ (total.autre[1].situation_anterieure+total.totalBanque[1].situation_anterieure).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[1].mouvements_entrants + total.totalBanque[1].mouvements_entrants).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[1].mouvements_sortants + total.totalBanque[1].mouvements_sortants).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[1].situation_a_date + total.totalBanque[1].situation_a_date).toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <!-- AFFICHAGE PESAGE -->
            <tbody v-if="inBase=='CONSOLIDE'">
              <tr>
                <td scope="row"
                    colspan="7"
                    class="tht-dark-blue text-center">TRESORERIE PESAGE</td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row">COMPTES FONCTIONNEMENT & INVESTISSEMENTS (PESAGE)</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[2].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[2].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[2].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[2].situation_a_date.toLocaleString() }}</td>
                <td></td>
              </tr>
            </tbody>

            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row">COMPTES PROVISIONS (PESAGE)</td>
                <td class=" text-right">{{ total.domiciliation_provision[2].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[2].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[2].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[2].situation_a_date.toLocaleString() }}</td>
                <td></td>
              </tr>
            </tbody>

            <tbody v-if="type_tresorerie!='caisse'&& (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row">COMPTES SEQUESTRES (PESAGE)</td>
                <td class=" text-right">{{ total.sequestre[2].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[2].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[2].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[2].situation_a_date.toLocaleString() }}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL BANQUES (PESAGE)</td>
                <td class="tht-blue text-right">{{ total.totalBanque[2].situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[2].mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[2].mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[2].situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL CAISSES (PESAGE)</td>
                <td class="tht-blue text-right">{{ total.autre[2].situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[2].mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[2].mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[2].situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL GENERALE PESAGE</td>
                <td class="tht-blue text-right">{{ (total.autre[2].situation_anterieure+total.totalBanque[2].situation_anterieure).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[2].mouvements_entrants + total.totalBanque[2].mouvements_entrants).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[2].mouvements_sortants + total.totalBanque[2].mouvements_sortants).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[2].situation_a_date + total.totalBanque[2].situation_a_date).toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <!-- AFFICHAGE AIRE DE STATIONNEMENT -->
            <tbody v-if="inBase=='CONSOLIDE'">
              <tr>
                <td scope="row"
                    colspan="7"
                    class="tht-dark-blue text-center">TRESORERIE AIRE STATIONNEMENT</td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row">COMPTES FONCTIONNEMENT & INVESTISSEMENTS (AIRE DE STATIONNEMENT)</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[3].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[3].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[3].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.fonctionnement_investissements[3].situation_a_date.toLocaleString() }}</td>
                <td></td>
              </tr>
            </tbody>

            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row">COMPTES PROVISIONS (AIRE DE STATIONNEMENT)</td>
                <td class=" text-right">{{ total.domiciliation_provision[3].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[3].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[3].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.domiciliation_provision[3].situation_a_date.toLocaleString() }}</td>
                <td></td>
              </tr>
            </tbody>
          
            <tbody v-if="type_tresorerie!='caisse'&& (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row">COMPTES SEQUESTRES (AIRE DE STATIONNEMENT)</td>
                <td class=" text-right">{{ total.sequestre[3].situation_anterieure.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[3].mouvements_entrants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[3].mouvements_sortants.toLocaleString() }}</td>
                <td class=" text-right">{{ total.sequestre[3].situation_a_date.toLocaleString() }}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL BANQUES (AIRE DE STATIONNEMENT)</td>
                <td class="tht-blue text-right">{{ total.totalBanque[3].situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[3].mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[3].mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.totalBanque[3].situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL CAISSES (AIRE DE STATIONNEMENT)</td>
                <td class="tht-blue text-right">{{ total.autre[3].situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[3].mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[3].mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.autre[3].situation_a_date.toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL GENERALE AIRE DE STATIONNEMENT</td>
                <td class="tht-blue text-right">{{ (total.autre[3].situation_anterieure+total.totalBanque[3].situation_anterieure).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[3].mouvements_entrants + total.totalBanque[3].mouvements_entrants).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[3].mouvements_sortants + total.totalBanque[3].mouvements_sortants).toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ (total.autre[3].situation_a_date + total.totalBanque[3].situation_a_date).toLocaleString() }}</td>
                <td class="tht-blue"></td>
              </tr>
            </tbody>
            <!-- TOTAL -->
            <tbody v-if="type_tresorerie=='tout'">
              <tr>
                <td scope="row"
                    class="tht-dark-blue">TOTAL GENERAL</td>
                <td class="tht-dark-blue text-right">{{ total.total.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.total.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.total.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.total.situation_a_date.toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->

    <table class="table tablestyle_3 table-striped my-4">
      <thead>
        <tr>
          <th class="tht-blue">Intitulé du compte (FER)</th>
          <th class="tht-blue text-right">Situation antérieure</th>
          <th class="tht-blue text-right">Mouvements entrants</th>
          <th class="tht-blue text-right">Mouvements sortants</th>
          <th class="tht-blue"></th>
          <th class="tht-blue text-right">Situation à date</th>
          <th class="tht-blue">Observations</th>
        </tr>
      </thead>
      <!-- TRESORERIE SIEGE -->
      <tbody v-if="inBase=='CONSOLIDE'">
        <tr>
          <td scope="row"
              colspan="7"
              class="tht-dark-blue text-center">TRESORERIE SIEGE</td>
        </tr>
      </tbody>
      <!-- COMPTES TRAVAUX ROUTIERS  -->
      <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
        <template v-for="(siege,sikey) in tresorerieSiege.donnees">
          <tr :key="sikey"
              v-if="siege.groupe=='COMPTES_TRAVAUX_ROUTIERS'">  
            <td scope="row">{{ siege.intitule_compte }}</td>
            <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
            <!-- <td>
              <input type="text"
                     v-if="form.groupe=='AUTRES'"
                     v-model="form.mouvement_entrant"/></td>
            <td><input type="text"
                       v-if="form.groupe=='AUTRES'"
                       v-model="form.mouvement_sortant"/></td>-->
            <td> 
              <button class="btn btn-blue btn-add"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#modal"
                      @click="form.id=siege.id_compte_bancaire,form.compte_bancaire=siege.id_compte_bancaire,form.groupe=siege.groupe">Ajouter</button></td>
            <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
            <td>{{ siege.observation }}</td>
          </tr>
        </template>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse'">
        <tr>
          <td scope="row"
              :class="color">COMPTES TRAVAUX ROUTIERS</td>
          <td class="text-right"
              :class="color">{{ total.travaux.situation_anterieure.toLocaleString() }}</td>
          <td class="text-right"
              :class="color">{{ total.travaux.mouvements_entrants.toLocaleString() }}</td>
          <td class="text-right"
              :class="color">{{ total.travaux.mouvements_sortants.toLocaleString() }}</td>
          <td :class="color"></td>
          <td class="text-right"
              :class="color">{{ total.travaux.situation_a_date.toLocaleString() }}</td>
          <td :class="color"></td>
        </tr>
      </tbody>
      
      <!-- COMPTES FONCTIONNEMENT & INVESTISSEMENTS  -->
      <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
        <template v-for="(siege,sikey) in tresorerieSiege.donnees">
          <tr :key="sikey"
              v-if="siege.groupe=='COMPTES_FONCTIONNEMENT_INVESTISSEMENTS'">  
            <td scope="row">{{ siege.intitule_compte }}</td>
            <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
            <!-- <td>
              <input type="text"
                     v-if="form.groupe=='AUTRES'"
                     v-model="form.mouvement_entrant"/></td>
            <td><input type="text"
                       v-if="form.groupe=='AUTRES'"
                       v-model="form.mouvement_sortant"/></td>-->
            <td> 
              <button class="btn btn-blue btn-add"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#modal"
                      @click="form.id=siege.id_compte_bancaire,form.compte_bancaire=siege.id_compte_bancaire,form.groupe=siege.groupe">Ajouter</button></td>
            <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
            <td>{{ siege.observation }}</td>
          </tr>
        </template>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase!='CONSOLIDE')">
        <tr>
          <td scope="row"
              class="tht-dark-blue">COMPTES FONCTIONNEMENT & INVESTISSEMENTS</td>
          <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
          <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.situation_a_date.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
        </tr>
      </tbody>
      
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="">COMPTES FONCTIONNEMENT & INVESTISSEMENTS</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[0].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[0].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[0].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.fonctionnement_investissements[0].situation_a_date.toLocaleString() }}</td>
          <td class=""></td>
        </tr>
      </tbody>

      <!-- COMPTES PROVISIONS  -->
      <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
        <template v-for="(siege,sikey) in tresorerieSiege.donnees">
          <tr :key="sikey"
              v-if="siege.groupe=='COMPTES_PROVISION'">  
            <td scope="row">{{ siege.intitule_compte }}</td>
            <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
            <!-- <td>
              <input type="text"
                     v-if="form.groupe=='AUTRES'"
                     v-model="form.mouvement_entrant"/></td>
            <td><input type="text"
                       v-if="form.groupe=='AUTRES'"
                       v-model="form.mouvement_sortant"/></td>-->
            <td> 
              <button class="btn btn-blue btn-add"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#modal"
                      @click="form.id=siege.id_compte_bancaire,form.compte_bancaire=siege.id_compte_bancaire,form.groupe=siege.groupe">Ajouter</button></td>
            <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
            <td>{{ siege.observation }}</td>
          </tr>
        </template>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
        <tr>
          <td scope="row"
              class="tht-dark-blue">COMPTES PROVISIONS</td>
          <td class="tht-dark-blue text-right">{{ total.domiciliation_provision.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.domiciliation_provision.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.domiciliation_provision.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
          <td class="tht-dark-blue text-right">{{ total.domiciliation_provision.situation_a_date.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="">COMPTES PROVISIONS</td>
          <td class=" text-right">{{ total.domiciliation_provision[0].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.domiciliation_provision[0].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.domiciliation_provision[0].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.domiciliation_provision[0].situation_a_date.toLocaleString() }}</td>
          <td class=""></td>
        </tr>
      </tbody>
      <!-- COMPTES SEQUESTRES  --> 
      <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
        <template v-for="(siege,sikey) in tresorerieSiege.donnees">
          <tr :key="sikey"
              v-if="siege.groupe=='COMPTES_RESERVE'">  
            <td scope="row">{{ siege.intitule_compte }}</td>
            <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
            <!-- <td>
              <input type="text"
                     v-if="form.groupe=='AUTRES'"
                     v-model="form.mouvement_entrant"/></td>
            <td><input type="text"
                       v-if="form.groupe=='AUTRES'"
                       v-model="form.mouvement_sortant"/></td>-->
            <td> 
              <button class="btn btn-blue btn-add"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#modal"
                      @click="form.id=siege.id_compte_bancaire,form.compte_bancaire=siege.id_compte_bancaire,form.groupe=siege.groupe">Ajouter</button></td>
            <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
            <td>{{ siege.observation }}</td>
          </tr>
        </template>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && inBase!='CONSOLIDE'">
        <tr>
          <td scope="row"
              class="tht-dark-blue">COMPTES SEQUESTRES</td>
          <td class="tht-dark-blue text-right">{{ total.sequestre.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.sequestre.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.sequestre.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
          <td class="tht-dark-blue text-right">{{ total.sequestre.situation_a_date.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="">COMPTES SEQUESTRES</td>
          <td class=" text-right">{{ total.sequestre[0].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.sequestre[0].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.sequestre[0].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.sequestre[0].situation_a_date.toLocaleString() }}</td>
          <td class=""></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && extBase!='CONSOLIDE'">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL BANQUES</td>
          <td class="tht-blue text-right">{{ total.totalBanque.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.totalBanque.situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL BANQUES</td>
          <td class="tht-blue text-right">{{ total.totalBanque[0].situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque[0].mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque[0].mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.totalBanque[0].situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <!-- AUTRES  --> 
      <tbody v-if="type_tresorerie!='banque' && inBase!='CONSOLIDE'">
        <template v-for="(siege,sikey) in tresorerieSiege.donnees">
          <tr :key="sikey"
              v-if="siege.groupe=='AUTRES'">  
            <td scope="row">{{ siege.intitule_compte }}</td>
            <td class="text-right">{{ Math.round(Number(siege.situation_anterieure)).toLocaleString() }}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_entrants)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(siege.mouvements_sortants)).toLocaleString()}}</td>
            <!-- <td>
              <input type="text"
                     v-if="form.groupe=='AUTRES'"
                     v-model="form.mouvement_entrant"/></td>
            <td><input type="text"
                       v-if="form.groupe=='AUTRES'"
                       v-model="form.mouvement_sortant"/></td>-->
            <td> 
              <button class="btn btn-blue btn-add"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#modal"
                      @click="form.id=siege.id_compte_bancaire,form.compte_bancaire=siege.id_compte_bancaire,form.groupe=siege.groupe">Ajouter</button></td>
            <td class="text-right">{{ Math.round(Number(siege.situation_a_date)).toLocaleString() }}</td>
            <td>{{ siege.observation }}</td>
          </tr>
        </template>
      </tbody>
      <tbody v-if="type_tresorerie!='banque' && inBase!='CONSOLIDE'">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL CAISSES</td>
          <td class="tht-blue text-right">{{ total.autre.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.autre.situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL CAISSES</td>
          <td class="tht-blue text-right">{{ total.autre[0].situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre[0].mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre[0].mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.autre[0].situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL GENERALE SIEGE</td>
          <td class="tht-blue text-right">{{ (total.autre[0].situation_anterieure+total.totalBanque[0].situation_anterieure).toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ (total.autre[0].mouvements_entrants + total.totalBanque[0].mouvements_entrants).toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ (total.autre[0].mouvements_sortants + total.totalBanque[0].mouvements_sortants).toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ (total.autre[0].situation_a_date + total.totalBanque[0].situation_a_date).toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <!-- AFFICHAGE PEAGE -->
      <tbody v-if="inBase=='CONSOLIDE'">
        <tr>
          <td scope="row"
              colspan="7"
              class="tht-dark-blue text-center">TRESORERIE PEAGE</td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="">COMPTES FONCTIONNEMENT & INVESTISSEMENTS (PEAGE)</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[1].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[1].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[1].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.fonctionnement_investissements[1].situation_a_date.toLocaleString() }}</td>
          <td class=""></td>
        </tr>
      </tbody>
      
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="">COMPTES PROVISIONS (PEAGE)</td>
          <td class=" text-right">{{ total.domiciliation_provision[1].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.domiciliation_provision[1].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.domiciliation_provision[1].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.domiciliation_provision[1].situation_a_date.toLocaleString() }}</td>
          <td class=""></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse'&& (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="">COMPTES SEQUESTRES (PEAGE)</td>
          <td class=" text-right">{{ total.sequestre[1].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.sequestre[1].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.sequestre[1].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.sequestre[1].situation_a_date.toLocaleString() }}</td>
          <td class=""></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL BANQUES (PEAGE)</td>
          <td class="tht-blue text-right">{{ total.totalBanque[1].situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque[1].mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque[1].mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.totalBanque[1].situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL CAISSES (PEAGE)</td>
          <td class="tht-blue text-right">{{ total.autre[1].situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre[1].mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre[1].mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.autre[1].situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL GENERALE PEAGE</td>
          <td class="tht-blue text-right">{{ (total.autre[1].situation_anterieure+total.totalBanque[1].situation_anterieure).toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ (total.autre[1].mouvements_entrants + total.totalBanque[1].mouvements_entrants).toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ (total.autre[1].mouvements_sortants + total.totalBanque[1].mouvements_sortants).toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ (total.autre[1].situation_a_date + total.totalBanque[1].situation_a_date).toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <!-- AFFICHAGE PESAGE -->
      <tbody v-if="inBase=='CONSOLIDE'">
        <tr>
          <td scope="row"
              colspan="7"
              class="tht-dark-blue text-center">TRESORERIE PESAGE</td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row">COMPTES FONCTIONNEMENT & INVESTISSEMENTS (PESAGE)</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[2].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[2].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[2].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.fonctionnement_investissements[2].situation_a_date.toLocaleString() }}</td>
          <td></td>
        </tr>
      </tbody>
      
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row">COMPTES PROVISIONS (PESAGE)</td>
          <td class=" text-right">{{ total.domiciliation_provision[2].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.domiciliation_provision[2].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.domiciliation_provision[2].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.domiciliation_provision[2].situation_a_date.toLocaleString() }}</td>
          <td></td>
        </tr>
      </tbody>
      
      <tbody v-if="type_tresorerie!='caisse'&& (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row">COMPTES SEQUESTRES (PESAGE)</td>
          <td class=" text-right">{{ total.sequestre[2].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.sequestre[2].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.sequestre[2].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.sequestre[2].situation_a_date.toLocaleString() }}</td>
          <td></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL BANQUES (PESAGE)</td>
          <td class="tht-blue text-right">{{ total.totalBanque[2].situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque[2].mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque[2].mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.totalBanque[2].situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL CAISSES (PESAGE)</td>
          <td class="tht-blue text-right">{{ total.autre[2].situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre[2].mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre[2].mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.autre[2].situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL GENERALE PESAGE</td>
          <td class="tht-blue text-right">{{ (total.autre[2].situation_anterieure+total.totalBanque[2].situation_anterieure).toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ (total.autre[2].mouvements_entrants + total.totalBanque[2].mouvements_entrants).toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ (total.autre[2].mouvements_sortants + total.totalBanque[2].mouvements_sortants).toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ (total.autre[2].situation_a_date + total.totalBanque[2].situation_a_date).toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <!-- AFFICHAGE AIRE DE STATIONNEMENT -->
      <tbody v-if="inBase=='CONSOLIDE'">
        <tr>
          <td scope="row"
              colspan="7"
              class="tht-dark-blue text-center">TRESORERIE AIRE STATIONNEMENT</td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row">COMPTES FONCTIONNEMENT & INVESTISSEMENTS (AIRE DE STATIONNEMENT)</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[3].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[3].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.fonctionnement_investissements[3].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.fonctionnement_investissements[3].situation_a_date.toLocaleString() }}</td>
          <td></td>
        </tr>
      </tbody>
      
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row">COMPTES PROVISIONS (AIRE DE STATIONNEMENT)</td>
          <td class=" text-right">{{ total.domiciliation_provision[3].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.domiciliation_provision[3].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.domiciliation_provision[3].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.domiciliation_provision[3].situation_a_date.toLocaleString() }}</td>
          <td></td>
        </tr>
      </tbody>

      <tbody v-if="type_tresorerie!='caisse'&& (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row">COMPTES SEQUESTRES (AIRE DE STATIONNEMENT)</td>
          <td class=" text-right">{{ total.sequestre[3].situation_anterieure.toLocaleString() }}</td>
          <td class=" text-right">{{ total.sequestre[3].mouvements_entrants.toLocaleString() }}</td>
          <td class=" text-right">{{ total.sequestre[3].mouvements_sortants.toLocaleString() }}</td>
          <td class=""></td>
          <td class=" text-right">{{ total.sequestre[3].situation_a_date.toLocaleString() }}</td>
          <td></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='caisse' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL BANQUES (AIRE DE STATIONNEMENT)</td>
          <td class="tht-blue text-right">{{ total.totalBanque[3].situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque[3].mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.totalBanque[3].mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.totalBanque[3].situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL CAISSES (AIRE DE STATIONNEMENT)</td>
          <td class="tht-blue text-right">{{ total.autre[3].situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre[3].mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.autre[3].mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.autre[3].situation_a_date.toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <tbody v-if="type_tresorerie!='banque' && (extBase=='CONSOLIDE' && loaded)">
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL GENERALE AIRE DE STATIONNEMENT</td>
          <td class="tht-blue text-right">{{ (total.autre[3].situation_anterieure+total.totalBanque[3].situation_anterieure).toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ (total.autre[3].mouvements_entrants + total.totalBanque[3].mouvements_entrants).toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ (total.autre[3].mouvements_sortants + total.totalBanque[3].mouvements_sortants).toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ (total.autre[3].situation_a_date + total.totalBanque[3].situation_a_date).toLocaleString() }}</td>
          <td class="tht-blue"></td>
        </tr>
      </tbody>
      <!-- TOTAL -->
      <tbody v-if="type_tresorerie=='tout'">
        <tr>
          <td scope="row"
              class="tht-dark-blue">TOTAL GENERAL</td>
          <td class="tht-dark-blue text-right">{{ total.total.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.total.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.total.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
          <td class="tht-dark-blue text-right">{{ total.total.situation_a_date.toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>
    <!-- <table class="table tablestyle_3 table-striped">
      <thead>
        <tr>
          <th class="tht-blue">Intitulé du compte (FER)</th>
          <th class="tht-blue">Situation antérieure</th>
          <th class="tht-blue">Mouvements entrants</th>
          <th class="tht-blue">Mouvements sortants</th>
          <th class="tht-blue">Situation à date</th>
          <th class="tht-blue">Observations</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row"></td>
          <td></td>
          <td><input type="text"/></td>
          <td><input type="text"/></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table> -->
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="modal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="modalLabel"
         aria-hidden="true">
      <div class="modal-dialog "
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="modalLabel">Ajouter un mouvement</h5>
            <button type="button"
                    class="close"
                    ref="btn-close-mouvement"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Mouvement entrant</label>
                <input type="text"
                       class="form-control"
                       @input="addSpace('mouvement_entrant')"
                       v-model="mouvement_entrant"/>
              </div>
              <div class="form-group">
                <label for="">Mouvement sortant</label>
                <input type="text"
                       class="form-control"
                       @input="addSpace('mouvement_sortant')"
                       v-model="mouvement_sortant"/>
              </div>
              <div class="form-group">
                <label for="">Observation</label>
                <textarea name="" 
                          id="" 
                          cols="30" 
                          rows="3"
                          class="form-control"
                          v-model="form.observation"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="EnvoyerMouvement(form)">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal importer tresorerie-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Importer données
                </div>
              </div>
            </div>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="btn-close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-11">
                  <label for="">Tresorerie journalière</label>
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.donnees_histo }}
                    </label>

                    <input type="file"
                           name="etat_comptable"
                           class="custom-file-input"
                           id="donnees_histo"
                           ref="donnees_histo"
                           v-on:change="handleFileUpload('donnees_histo')"
                           accept=".xlsx,.csv" />
                    <span v-if="formTresorerie.errors().has('donnees_histo')"
                          v-text="form.errors().get('donnees_histo')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-5">
                  <Loader v-show="loading"/>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="templateDownload('ModelImportationTresorerie.xlsx')">Télécharger un template</button>
            <button type="button"
                    class="btn btn-f-blue"
                    @click="closeModal()"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    :disabled="url == ''"
                    @click="submit">Importer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
button.btn-add{
  font-size: 0.8em;
}
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
// import Notif from "@/components/shared/Toast"
import Notif from "@/components/shared/ToastB"
import VueHtml2pdf from 'vue-html2pdf'
import utils from "@/assets/js/utils"
import form from "vuejs-form"
import Loader from "@/components/shared/Spinner"

export default {
  name:"Siege",
  props:["extBase","dateJour"],
  components: {
    Notif,
    VueHtml2pdf,
    Loader
  },
  data: ()=>({
    date_jour: "",
    form:{
      id:"",
      source:"",
      groupe:"",
      base:"",
      compte_bancaire:"", //siege.compte_bancaire,
      mouvement_entrant:"",
      mouvement_sortant:"",
      observation:"",
      date:"",
    },
    color:"",
    type_tresorerie:"tout",
    inBase:"SIEGE",
    mouvement_entrant:"",
    mouvement_sortant:"",
    loaded:false,
    loading:false,
    uploadfile :"",
    inputFiles: {  
      donnees_histo:"" 
    },
    url:"",
    listExtensionFiles: [],
    showing:"balance",
    formTresorerie: form({
      donnees_histo:"" 
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
    notif: {
      activated: "",
      type: "",
      message: "",
      button:""
    },
    total:{
      travaux:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      fonctionnement_investissements:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      domiciliation_provision:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      sequestre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      autre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      totalBanque:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      total:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
    },
  }),
  watch:{
    extBase(){
      if (this.extBase) {
        this.inBase=""
        this.inBase=this.extBase
        this.form.base=this.inBase
        // var jour = new Date()
        // this.dateJour = jour.getFullYear()+"-"
        // if (jour.getMonth() < 9) {
        //   this.dateJour +="0"
        // }
        // this.dateJour+=(jour.getMonth()+1)+"-"
        // if (jour.getDate() < 10) {
        //   this.dateJour +="0" 
        // }
        // this.dateJour+=jour.getDate()
        this.loadTresorerieSiege({date:this.dateJour,base:this.inBase})
      }
    },
    tresorerieSiege(){
      if (this.tresorerieSiege) {
        this.form=[]
        console.log(this.extBase)
        if (this.extBase!="CONSOLIDE") {
          this.total={
            travaux:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            fonctionnement_investissements:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            domiciliation_provision:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            sequestre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            autre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            totalBanque:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            total:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          }
        }else{
          this.total={
            travaux:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            fonctionnement_investissements:[
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            ],
            domiciliation_provision:[
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            ],
            sequestre:[
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            ],
            autre:[
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            ],
            totalBanque:[
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
              {situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
            ],
            total:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          }
        }
        // Création des formualaires par ligne
        this.form={
          id:"",
          source:"",
          groupe:"",
          base:this.inBase,
          compte_bancaire:"", //siege.compte_bancaire,
          mouvement_entrant:"",
          mouvement_sortant:"",
          observation:"",
          date:this.dateJour}
        this.tresorerieSiege.donnees.forEach(siege => {
          
          if (this.extBase!="CONSOLIDE") {
            // calcul des totaux base simple
            if (siege.groupe=='COMPTES_TRAVAUX_ROUTIERS') {
              this.total.travaux.situation_anterieure+=Math.round(Number(siege.situation_anterieure))
              this.total.travaux.mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
              this.total.travaux.mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
              this.total.travaux.situation_a_date+=Math.round(Number(siege.situation_a_date))           
            }
            if (siege.groupe=='COMPTES_FONCTIONNEMENT_INVESTISSEMENTS') {
              this.total.fonctionnement_investissements.situation_anterieure+=Math.round(Number(siege.situation_anterieure))
              this.total.fonctionnement_investissements.mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
              this.total.fonctionnement_investissements.mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
              this.total.fonctionnement_investissements.situation_a_date+=Math.round(Number(siege.situation_a_date))
            }
            if (siege.groupe=='COMPTES_PROVISION') {
              this.total.domiciliation_provision.situation_anterieure+=Math.round(Number(siege.situation_anterieure))
              this.total.domiciliation_provision.mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
              this.total.domiciliation_provision.mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
              this.total.domiciliation_provision.situation_a_date+=Math.round(Number(siege.situation_a_date))
            }
            if (siege.groupe=='COMPTES_RESERVE') {
              this.total.sequestre.situation_anterieure+=Math.round(Number(siege.situation_anterieure))
              this.total.sequestre.mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
              this.total.sequestre.mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
              this.total.sequestre.situation_a_date+=Math.round(Number(siege.situation_a_date))
            }
            if (siege.groupe=='AUTRES') {
              this.total.autre.situation_anterieure+=Math.round(Number(siege.situation_anterieure))
              this.total.autre.mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
              this.total.autre.mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
              this.total.autre.situation_a_date+=Math.round(Number(siege.situation_a_date))
            }
            if (siege.groupe!='AUTRES' && (siege.groupe!=null && siege.groupe!="")) {
              this.total.totalBanque.situation_anterieure+=Math.round(Number(siege.situation_anterieure))
              this.total.totalBanque.mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
              this.total.totalBanque.mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
              this.total.totalBanque.situation_a_date+=Math.round(Number(siege.situation_a_date))
            }
          } else {
            // Calcul des totaux base consolidée
            // calcul des totaux base simple
            if (siege.groupe=='COMPTES_TRAVAUX_ROUTIERS') {
              if (siege.base=="SIEGE") {
                this.total.travaux.situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                this.total.travaux.mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                this.total.travaux.mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                this.total.travaux.situation_a_date+=Math.round(Number(siege.situation_a_date))    
              }
            }
            if (siege.groupe=='COMPTES_FONCTIONNEMENT_INVESTISSEMENTS') {
              switch (siege.base) {
                case "SIEGE":
                  this.total.fonctionnement_investissements[0].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.fonctionnement_investissements[0].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.fonctionnement_investissements[0].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.fonctionnement_investissements[0].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PEAGE":
                  this.total.fonctionnement_investissements[1].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.fonctionnement_investissements[1].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.fonctionnement_investissements[1].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.fonctionnement_investissements[1].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PESAGE":
                  this.total.fonctionnement_investissements[2].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.fonctionnement_investissements[2].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.fonctionnement_investissements[2].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.fonctionnement_investissements[2].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break

                default:
                  this.total.fonctionnement_investissements[3].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.fonctionnement_investissements[3].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.fonctionnement_investissements[3].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.fonctionnement_investissements[3].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
              }
            }
            if (siege.groupe=='COMPTES_PROVISION') {
              switch (siege.base) {
                case "SIEGE":
                  this.total.domiciliation_provision[0].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.domiciliation_provision[0].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.domiciliation_provision[0].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.domiciliation_provision[0].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PEAGE":
                  this.total.domiciliation_provision[1].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.domiciliation_provision[1].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.domiciliation_provision[1].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.domiciliation_provision[1].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PESAGE":
                  this.total.domiciliation_provision[2].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.domiciliation_provision[2].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.domiciliation_provision[2].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.domiciliation_provision[2].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break

                default:
                  this.total.domiciliation_provision[3].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.domiciliation_provision[3].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.domiciliation_provision[3].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.domiciliation_provision[3].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
              }
            }
            if (siege.groupe=='COMPTES_RESERVE') {
              switch (siege.base) {
                case "SIEGE":
                  this.total.sequestre[0].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.sequestre[0].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.sequestre[0].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.sequestre[0].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PEAGE":
                  this.total.sequestre[1].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.sequestre[1].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.sequestre[1].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.sequestre[1].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PESAGE":
                  this.total.sequestre[2].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.sequestre[2].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.sequestre[2].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.sequestre[2].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break

                default:
                  this.total.sequestre[3].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.sequestre[3].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.sequestre[3].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.sequestre[3].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
              }
            }
            if (siege.groupe=='AUTRES') {
              switch (siege.base) {
                case "SIEGE":
                  this.total.autre[0].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.autre[0].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.autre[0].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.autre[0].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PEAGE":
                  this.total.autre[1].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.autre[1].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.autre[1].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.autre[1].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PESAGE":
                  this.total.autre[2].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.autre[2].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.autre[2].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.autre[2].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break

                default:
                  this.total.autre[3].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.autre[3].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.autre[3].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.autre[3].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
              }
            }
            if (siege.groupe!='AUTRES') {
              switch (siege.base) {
                case "SIEGE":
                  this.total.totalBanque[0].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.totalBanque[0].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.totalBanque[0].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.totalBanque[0].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PEAGE":
                  this.total.totalBanque[1].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.totalBanque[1].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.totalBanque[1].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.totalBanque[1].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
                case "PESAGE":
                  this.total.totalBanque[2].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.totalBanque[2].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.totalBanque[2].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.totalBanque[2].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break

                default:
                  this.total.totalBanque[3].situation_anterieure+=Math.round(Number(siege.situation_anterieure))
                  this.total.totalBanque[3].mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
                  this.total.totalBanque[3].mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
                  this.total.totalBanque[3].situation_a_date+=Math.round(Number(siege.situation_a_date))    
                  break
              }
            }
          }
          // Total Générale
          if (siege.groupe!=null && siege.groupe!="") {
            this.total.total.situation_anterieure+=Math.round(Number(siege.situation_anterieure))
            this.total.total.mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
            this.total.total.mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
            this.total.total.situation_a_date+=Math.round(Number(siege.situation_a_date))
          }
          
        })
        this.loaded=true
        console.log(this.total)
      }
    },
    mouvement(){
      if (this.mouvement) {
        this.setTresorerieSiege("")
        console.log(this.notif)
        this.notif.message = "Mouvement enregistré"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            // Création des formualaires par ligne
            this.mouvement_entrant=""
            this.mouvement_sortant=""
            this.form={
              id:"",
              source:"",
              groupe:"",
              base: this.inBase,
              compte_bancaire:"", //siege.compte_bancaire,
              mouvement_entrant:"",
              mouvement_sortant:"",
              observation:"",
              date:this.dateJour}
            this.$refs["btn-close-mouvement"].click()
            this.setMouvement("")
            this.loadTresorerieSiege({date:this.dateJour,base:this.inBase})
          }.bind(this),
          2000
        )
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.formTresorerie[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.formTresorerie[this.uploadfile]
        this.loading=false
      }
    },
    dateJour(){
      if (this.dateJour) {
        this.launchRequest()
      }
    },
    importTresorerie(){
      if (this.importTresorerie) {
        this.notif.message = "Fichier a été importé"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$refs["btn-close"].click()
            this.setImportTresorerie("")
            this.loadTresorerieSiege({date:this.dateJour,base:this.inBase})
          }.bind(this),
          2000
        )
      }
    },
    msgFailImportTresorerie(){
      if (this.msgFailImportTresorerie) {
        var erreur= this.msgFailImportTresorerie.split("#")
        this.notif.message = erreur[0]
        this.notif.button=erreur[1]
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.notif.button=""
            this.setsetMsgFailImportTresorerie("")
          }.bind(this),
          10000
        )
      }
    }
  },
  created(){
    this.inBase=""
    this.inBase=this.extBase
    if (this.inBase!="CONSOLIDE") {
      this.color="tht-dark-blue"
      this.form.base=this.inBase
    }
    // var jour = new Date()
    // this.dateJour = jour.getFullYear()+"-"
    // if (jour.getMonth() < 9) {
    //   this.dateJour +="0"
    // }
    // this.dateJour+=(jour.getMonth()+1)+"-"
    // if (jour.getDate() < 10) {
    //   this.dateJour +="0" 
    // }
    // this.dateJour+=jour.getDate()
    this.loadTresorerieSiege({date:this.dateJour,base:this.inBase})
    this.listExtensionFiles = utils.formaFichierAccepte

  },
  computed:{
    ...mapGetters(["tresorerieSiege","msgFailTresorerieSiege","mouvement","msgFailMouvement","importTresorerie","msgFailImportTresorerie","files",])
  },
  methods:{
    ...mapActions(["loadTresorerieSiege", "sendMouvement","importerTresorerie","saveFile",]),
    ...mapMutations(["setTresorerieSiege","setMsgFailTresorerieSiege","setMouvement","setMsgFailMouvement","setImportTresorerie","setMsgFailImportTresorerie","setFile",]),
    launchRequest(){
      this.loadTresorerieSiege({date:this.dateJour,base:this.inBase})
    },
    EnvoyerMouvement(form){
      if (form.observation == null) {
        form.observation = ""
      }
      if (form.mouvement_entrant=="" || form.mouvement_entrant==null) {
        form.mouvement_entrant=0
      }
      if (form.mouvement_sortant=="" || form.mouvement_sortant==null) {
        form.mouvement_sortant=0
      }
      console.log(form)
      this.sendMouvement(form)
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (index==0) {
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " " && splinter[index]!='-') {
              splinter[index] = ""
            }
          }else{
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
              splinter[index] = ""
            }
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    handleFileUpload(filename) {
      if (this.$refs[filename].files[0]) {
        this.inputFiles[filename] = this.$refs[filename].files[0].name
        if (!this.inputFiles[filename].includes(" ")) {
          this.loading=true
          var extension = this.inputFiles[filename].split(".")[1]
          if (this.listExtensionFiles.includes(extension)) {
            this.uploadfile = filename
            this.saveFile(this.$refs[filename].files[0])
          } else {
            this.$refs[filename].disabled = false
            this.inputFiles[filename] = ""
            this.loading=false
            this.notif.message = "Mauvais Format du fichier"
            this.notif.type = "toast-danger"
            this.notif.activated = "activated"
            setTimeout(
              function() {
                this.notif.activated = ""
                this.setErors("")
              }.bind(this),
              5000
            )
          }  
        }else{
          this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          this.loadrarc = false
          this.loading= false
          setTimeout(
            function() {
              this.notif.activated = ""
              this.$refs[filename].value=""
              this.inputFiles[filename]=""
            }.bind(this),
            3000
          )
        }
      }
    },
    submit() {
      this.importerTresorerie({url:this.url})
    },
  }
}
</script>
