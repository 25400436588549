<template>
  <div class="overlay mx-auto"
       :class="this.notif.activated">
    <div class="form-message"
         :class="this.notif.activated + ' ' + this.notif.type">
      <p v-if="this.notif.type == 'toast-warning' || this.notif.type == 'toast-custom-warning'">&#9888; {{ this.notif.message }}</p>
      <p v-if="this.notif.type == 'toast-success' || this.notif.type == 'toast-custom-success'">&#10004; {{ this.notif.message }}</p>
      <p v-if="this.notif.type == 'toast-danger' || this.notif.type == 'toast-custom-danger'">&#10006; {{ this.notif.message }}</p>
      <div class="row" 
           v-if="this.notif.button && (this.notif.button!='' || this.notif.button!=null)">
        <button type="button" 
                class="btn btn-blue mx-auto"
                @click="downloadExport(notif.button)">
          Télécharger la liste des comptes manquants
        </button>  
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Notif",
  props: ["notif"],
  methods:{
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+this.notif.button
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
      this.notif.activated=""
    }
  }
}
</script>
