<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        Trésorerie > Tableau de bord
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Tableau de bord de trésorerie
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-2">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <label for="">Base:</label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="base"
                  @change="changeBase(base)">
            <option value="SIEGE">Siège</option>
            <option value="PEAGE">Péage</option>
            <option value="PESAGE">Pesage</option>
            <option value="AIRE_STATIONNEMENT">Aire de stationnement</option>
            <option value="CONSOLIDE">Consolidé</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">Date du jour:</label>
          <input type="date" 
                 class="form-control ml-2"
                 v-model="date_jour"/>
        </div>
        <div class="form-group ml-3">
          <button type="button"
                  class="btn btn-info"
                  @click="sendDate()">
            Rechercher
          </button>
        </div>
        <!-- <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div> -->
        
      </form>

    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <!-- <div class="row mt-1 mb-3">
      <div class="col-md-5 mx-auto">
        <form class="form form-inline">
          <div class="form-group">
            <label for="">Date du jour:</label>
            <input type="date" 
                   class="form-control ml-2"
                   v-model="date_jour"/>
          </div>
          <div class="form-group ml-3">
            <button type="button"
                    class="btn btn-info"
                    @click="sendDate()">
              Rechercher
            </button>
          </div>
        </form>
      </div>
    </div> -->
    <div class="inner_container">
      <ul class="nav nav-tabs"
          id="myTab" 
          role="tablist">
        <!-- <li class="nav-item" 
            role="presentation">
          <a class="nav-link active"
             id="home-tab"
             data-toggle="tab"
             href="#home"
             role="tab"
             aria-controls="home"
             aria-selected="true"
             @click="choix='generale'">Trésorerie générale</a>
        </li> -->
        <li class="nav-item"
            role="presentation">
          <a class="nav-link active"
             id="profile-tab"
             data-toggle="tab"
             href="#profile"
             role="tab"
             aria-controls="profile"
             aria-selected="true"
             @click="choix='siege'">Trésorerie journalière</a>
        </li>
        <!-- <li class="nav-item" 
            role="presentation">
          <a class="nav-link"
             id="contact-tab"
             data-toggle="tab"
             href="#contact"
             role="tab"
             aria-controls="contact"
             aria-selected="true"
             @click="choix='concession'">Trésorerie concession</a>
        </li> -->
        <li class="nav-item" 
            role="presentation">
          <a class="nav-link"
             id="fonction-tab"
             data-toggle="tab"
             href="#fonction"
             role="tab"
             aria-controls="fonction"
             aria-selected="true"
             @click="choix='fonction'">
            <span v-if="base=='SIEGE'">Fonctionnement et Investissements</span>
            <span v-else>Fonctionnement, Investissements et Travaux</span>
          </a>
        </li>
        <li class="nav-item" 
            role="presentation"
            v-if="base=='SIEGE'">
          <a class="nav-link"
             id="travaux-tab"
             data-toggle="tab"
             href="#travaux"
             role="tab"
             aria-controls="travaux"
             aria-selected="true"
             @click="choix='travaux'">Travaux routiers</a>
        </li>
        <li class="nav-item" 
            role="presentation">
          <a class="nav-link"
             id="engagement-tab"
             data-toggle="tab"
             href="#engagement"
             role="tab"
             aria-controls="engagement"
             aria-selected="true"
             @click="choix='engagement'">Engagements Financiers</a>
        </li>
      </ul>
      <div class="tab-content"
           id="myTabContent">
        <div class="tab-pane fade"
             id="home"
             role="tabpanel" 
             aria-labelledby="home-tab">
          <Generale :extYear="year"
                    v-if="choix=='generale'"/>
        </div>
        <div class="tab-pane fade show active"
             id="profile" 
             role="tabpanel" 
             aria-labelledby="profile-tab">
          <Siege v-if="choix=='siege'"
                 :extBase="newbase"
                 :dateJour="date_send"/>
        </div>
        <div class="tab-pane fade"
             id="contact" 
             role="tabpanel" 
             aria-labelledby="contact-tab">
          <Concession v-if="choix=='concession'"/>
        </div>
        <div class="tab-pane fade"
             id="fonction" 
             role="tabpanel" 
             aria-labelledby="fonction-tab">
          <FonctionnementInvestissement v-if="choix=='fonction'"
                                        :extYear="year"
                                        :dateJour="date_send"
                                        :extBase="newbase"/>
        </div>
        <div class="tab-pane fade"
             id="travaux" 
             role="tabpanel" 
             aria-labelledby="travaux-tab">
          <TravauxRoutier v-if="choix=='travaux'"
                          :extYear="year"
                          :dateJour="date_send"
                          :extBase="newbase"/>
        </div>
        <div class="tab-pane fade"
             id="engagement" 
             role="tabpanel" 
             aria-labelledby="engagement-tab">
          <Engagements v-if="choix=='engagement'"
                       :extYear="year"
                       :dateJour="date_send"
                       :extBase="newbase"/>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
/* ul.nav li.nav-item a.nav-link,
form.form-inline input,
form.form-inline select,
button.btn{
  font-size: 0.8em;
} */
</style>
<script>

import { mapActions, mapGetters, mapMutations } from "vuex"
import Generale from "./Generale.vue"
import Siege from "./Siege.vue"
import Concession from "./Concession.vue"
import FonctionnementInvestissement from "./FonctionnementIvestissement.vue"
import TravauxRoutier from "./TravauxRoutiers.vue"
import Engagements from "./Engagements.vue"
import Notif from "@/components/shared/Toast"

export default{
  name:"Tresorerie",
  components:{
    Generale,
    Siege,
    Concession,
    FonctionnementInvestissement,
    TravauxRoutier,
    Engagements,
    Notif,
  },
  data:()=>({
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
    },
    notif: {
      activated: "",
      type: "",
      message: "",
    },
    date_jour:"",
    date_send:"",
    long:0,
    base:"SIEGE",
    newbase:"SIEGE",
    loaded:false,
    annees:0,
    dettes:[],
    sorties:[],
    choix:"siege",
    solde_tresorerie:[],
    randomChart1: {
      labels: [new Date().getFullYear()],
      datasets: [
        {
          label: "Entrées",
          data: [30, 20, 40, 50, 5],
          backgroundColor: ["#0097a9"]
        },
        {
          label: "Sorties",
          data: [30, 20, 40, 50, 5],
          backgroundColor: ["#016677"]
        },
      ]
    },
    optionsChart1: {
      responsive: true,
      maintainAspectRatio: false
    },
  }),
  watch:{
    // tresorerie(){
    //   if (this.tresorerie.donnees) {
    //     this.long = this.tresorerie.donnees.banque.length
    //     this.annees= this.annee_debut.split("-")
    //     this.annees= this.annees[0]
    //     if (this.tresorerie.donnees.decompte) {
    //       this.getEngagementFinancier(this.year)
    //     }
    //     console.log(this.annees)
    //   }
    // },
    // engagementFinancier(){
    //   if (this.engagementFinancier) {
    //     this.dettes=[]
    //     for (let index = 0; index < this.long; index++) {
    //       this.dettes.push(0)
    //       this.engagementFinancier.engagement_financier.forEach(engagement => {
    //         var annee_engagement=0
    //         annee_engagement = engagement.date_debut.split("-")
    //         annee_engagement = annee_engagement[0]
    //         if ((Number(this.annees)+index) == annee_engagement) {
    //           this.dettes[index] += engagement.montant_rembourse_interet + engagement.montant_rembourse_principal  
    //         }
    //       })          
    //     }
    //     this.sorties=[]
    //     this.solde_tresorerie=[]
    //     this.randomChart1.labels=[]
    //     this.randomChart1.datasets[1].data=[]
    //     this.randomChart1.datasets[1].backgroundColor=[]
    //     this.randomChart1.datasets[0].data=[]
    //     this.randomChart1.datasets[0].backgroundColor=[]

    //     for (let index = 0; index < this.dettes.length; index++) {
    //       this.sorties.push(this.dettes[index] + this.tresorerie.donnees.decompte[index])
    //       this.solde_tresorerie.push(this.tresorerie.donnees.banque[index]-this.sorties[index])
    //       this.randomChart1.labels.push(Number(this.annees)+index)
    //       this.randomChart1.datasets[0].data.push(this.tresorerie.donnees.banque[index])
    //       this.randomChart1.datasets[0].backgroundColor.push("#0097a9")
    //       this.randomChart1.datasets[1].data.push(this.sorties[index])
    //       this.randomChart1.datasets[1].backgroundColor.push("#016677")
    //     }
    //     this.loaded=true
    //   }
    // }
  },
  created(){
    this.year.annee_debut= this.annee_debut
    this.year.annee_fin= this.annee_fin
    var jour = new Date()
    this.date_jour = jour.getFullYear()+"-"
    if (jour.getMonth() < 9) {
      this.date_jour +="0"
    }
    this.date_jour+=(jour.getMonth()+1)+"-"
    if (jour.getDate() < 10) {
      this.date_jour +="0" 
    }
    this.date_jour+=jour.getDate()
    this.date_send=this.date_jour
    console.log(this.newbase)
    // this.loadTresorerie(this.year)
  },
  computed: {
    ...mapGetters(["tresorerie","msgFailTresorerie","engagementFinancier"])
  },
  methods: {
    ...mapActions(["loadTresorerie","getEngagementFinancier"]),
    ...mapMutations(["setTresorerie","setMsgFailTresorerie","setEngagementFinancier","setReponseError"]),
    changeYear() {
      this.loaded=false
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      // this.loaded = true
      this.setTresorerie("")
      this.setEngagementFinancier("")
      // this.getEngagementFinancier(this.year)
      this.loadTresorerie(this.year)
    },
    changeBase(base){
      this.newbase = ""
      this.newbase = base
      var recup = this.choix
      this.choix = ""
      setTimeout(
        function() {
          this.choix = recup            
        }.bind(this),
        500
      )
      console.log(this.newbase)
    },
    sendDate(){
      this.date_send=""
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      console.log(this.date_jour)
      if ((this.date_jour <= this.annee_fin) && (this.date_jour >= this.annee_debut)) {
        this.date_send=this.date_jour
      }else{
        this.notif.message = "La date doit être comprise dans la période."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.notif.message = ""
          }.bind(this),
          2000
        )
      }
      
    }
  }
}
</script>