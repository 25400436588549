<template>
  <div>
    <div class="row mt-3">
      <!-- <div class="col-md-2 mx-auto">
        <form class="form form-inline">
          <div class="form-group">
            <label for="">Date:</label>
            <input type="date" 
                   class="form-control ml-2"
                   v-model="dateJour"
                   @change="launchRequest()"/>
          </div>
        </form>
      </div> -->
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  :filename="'Flux_libres_Fonctionnement_investissement_'+dateJour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-3">
          <div class="col-3 ml-4">
            <img src="../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ dateJour }}
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Flux libres disponibles de Travaux routiers
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <div class="row mt-3 justify-content-center">
            <div class="col-md-1 mt-2"
                 style="border-left: 2px solid rgb(34, 96, 153);border-top: 2px solid rgb(34, 96, 153);">
            </div>
            <div class="col-md-6 text-center mb-4"
                 style="background-color: rgb(34, 96, 153); color: white;border-radius: 8px;">
              Travaux routiers
            </div>
            <div class="col-md-1 mt-2"
                 style="border-right: 2px solid rgb(34, 96, 153);border-top: 2px solid rgb(34, 96, 153);">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-5 text-center mx-auto"
                 style="background-color: rgb(34, 96, 153); color: white;border-radius: 8px;">
              Solde de trésorerie Travaux routiers (a) <br/>
              {{Math.round(total.travaux.situation_a_date).toLocaleString()}}
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5 text-center mx-auto"
                 style="background-color: rgb(34, 96, 153); color: white;border-radius: 8px;">
              Dettes Travaux routiers (b) <br/>
              <span v-if="detteFournisseur">{{(dette).toLocaleString()}}</span>
              <span v-else>0</span>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-md-6 text-center mt-4"
                 style="background-color: rgb(34, 96, 153); color: white;border-radius: 8px;">
              Flux libres (c) = (a -b ) <br/>
              <span v-if="detteFournisseur"
                    :class="coloresult">{{(total.travaux.situation_a_date-detteFournisseur.dette_fournisseur).toLocaleString()}}</span>
              <span v-else
                    :class="coloresult">{{Math.round(total.travaux.situation_a_date).toLocaleString()}}</span>
            </div>
            <div class="col-md-1 mb-4"
                 style="border-right: 2px solid rgb(34, 96, 153);border-bottom: 2px solid rgb(34, 96, 153);">
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <div class="row mt-4 justify-content-center">
            <h3>Commentaire:</h3>
          </div>
          <div class="row mt-3">
            <div class="col-md-9 mx-auto text-capitalize">
              {{ commentaire }}
            </div>
          </div>
        </div>
        <!-- <div style="width: 95%;margin: auto;">
          <table class="table tablestyle_3 table-striped my-4">
            <thead>
              <tr>
                <th class="tht-blue">Libellé</th>
                <th class="tht-blue">Montant</th>
              </tr>
            </thead>
            <! -- COMPTES TRAVAUX -- >
            <tbody>
              <tr>  
                <td scope="row">Solde de trésorerie des comptes Travaux</td>
                <td class="text-right">{{Math.round(total.travaux.situation_a_date).toLocaleString()}}</td>    
              </tr>
              <tr>  
                <td scope="row">Dettes Fournisseurs Travaux</td>
                <td class="text-right"
                    v-if="detteFournisseur">{{(detteFournisseur.dette_fournisseur).toLocaleString()}}</td>    
                <td class="text-right"
                    v-else>0</td>
              </tr>
              <tr>  
                <td scope="row">Flux libres</td>
                <td class="text-right"
                    :class="coloresult">
                  {{(total.travaux.situation_a_date-detteFournisseur.dette_fournisseur).toLocaleString()}}
                </td>  
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <div class="row">
      <Notif :notif="notif" />
    </div>
    
    <div class="row mt-3 justify-content-center">
      <div class="col-md-1 mt-2"
           style="border-left: 2px solid rgb(34, 96, 153);border-top: 2px solid rgb(34, 96, 153);">
      </div>
      <div class="col-md-6 text-center mb-4"
           style="background-color: rgb(34, 96, 153); color: white;border-radius: 8px;">
        Travaux routiers
      </div>
      <div class="col-md-1 mt-2"
           style="border-right: 2px solid rgb(34, 96, 153);border-top: 2px solid rgb(34, 96, 153);">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-5 text-center mx-auto"
           style="background-color: rgb(34, 96, 153); color: white;border-radius: 8px;">
        Solde de trésorerie Travaux routiers (a) <br/>
        {{Math.round(total.travaux.situation_a_date).toLocaleString()}}
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5 text-center mx-auto"
           style="background-color: rgb(34, 96, 153); color: white;border-radius: 8px;">
        Dettes Travaux routiers (b) <br/>
        <span v-if="detteFournisseur">{{(dette).toLocaleString()}}</span>
        <span v-else>0</span>
      </div>
    </div>
    <div class="row mt-1 justify-content-center">
      <div class="col-md-6 text-center mt-4"
           style="background-color: rgb(34, 96, 153); color: white;border-radius: 8px;">
        Flux libres (c) = (a -b ) <br/>
        <span v-if="detteFournisseur"
              :class="coloresult">{{(total.travaux.situation_a_date-detteFournisseur.dette_fournisseur).toLocaleString()}}</span>
        <span v-else
              :class="coloresult">{{Math.round(total.travaux.situation_a_date).toLocaleString()}}</span>
      </div>
      <div class="col-md-1 mb-4"
           style="border-right: 2px solid rgb(34, 96, 153);border-bottom: 2px solid rgb(34, 96, 153);">
      </div>
    </div>  
    <div class="row mt-3">
      <div class="form-group col-md-5 mx-auto">
        <label for="">Commentaire:</label>
        <textarea class="form-control" 
                  name="" 
                  id="" 
                  rows="5"
                  placeholder="Votre message"
                  v-model="commentaire"></textarea>
      </div>
    </div>
    <!-- <table class="table tablestyle_3 table-striped my-4">
      <thead>
        <tr>
          <th class="tht-blue">Libellé</th>
          <th class="tht-blue">Montant</th>
        </tr>
      </thead>
      <! -- COMPTES TRAVAUX -- >
      <tbody>
        <tr>  
          <td scope="row">Solde de trésorerie des comptes Travaux</td>
          <td class="text-right">{{Math.round(total.travaux.situation_a_date).toLocaleString()}}</td>    
        </tr>
        <tr>  
          <td scope="row">Dettes Fournisseurs Travaux</td>
          <td class="text-right"
              v-if="detteFournisseur">{{(detteFournisseur.dette_fournisseur).toLocaleString()}}</td>    
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>  
          <td scope="row">Flux libres</td>
          <td class="text-right"
              :class="coloresult">
            {{(total.travaux.situation_a_date-detteFournisseur.dette_fournisseur).toLocaleString()}}
          </td>  
        </tr>
        
      </tbody>
      
    </table> -->
  </div>
</template>
<style>
i.flaticon-add{
  font-size: 0.6em;
}
.bad-news{
  color:#ff0000;
}
.good-news{
  color:#24b13c;
}
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import VueHtml2pdf from 'vue-html2pdf'

export default ({
  name:"TravauxRoutier",
  props:["extYear","extBase","dateJour"],
  components: {
    Notif,
    VueHtml2pdf
  },
  data: ()=>({
    date_jour: "",
    form:[],
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    commentaire:'',
    loaded:false,
    year:{
      annee_debut:"",
      annee_fin:"",
      periode:"mois"
    },
    dette:0,
    coloresult:"",
    total:{
      travaux:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      fonctionnement_investissements:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      domiciliation_provision:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      sequestre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      autre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      total:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
    },
  }),
  watch:{
    tresorerieSiege(){
      if (this.tresorerieSiege) {
        this.form=[]
        this.total={
          travaux:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          fonctionnement_investissements:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          domiciliation_provision:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          sequestre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          autre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          total:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
        }
        // Création des formualaires par ligne
        this.tresorerieSiege.donnees.forEach(siege => {
          this.form.push({
            id:siege.id_compte_bancaire,
            groupe:siege.groupe,
            mouvement_entrant:siege.mouvements_entrants,
            mouvement_sortant:siege.mouvements_sortants,
            observation:siege.observation,
            date:this.dateJour})
          // calcul des totaux
          if (siege.groupe=='COMPTES_TRAVAUX_ROUTIERS') {
            this.total.travaux.situation_anterieure+=Math.round(Number(siege.situation_anterieure))
            this.total.travaux.mouvements_entrants+=Math.round(Number(siege.mouvements_entrants))
            this.total.travaux.mouvements_sortants+=Math.round(Number(siege.mouvements_sortants))
            this.total.travaux.situation_a_date+=Math.round(Number(siege.situation_a_date))
          }
          
        })
        console.log(this.total)
        this.loadDetteFournisseur({date_debut:this.extYear.annee_debut,date_fin:this.extYear.annee_fin,groupe:"DETTES_FOURNISSEUR_TRAVAUX",base:this.extBase})

      }
    },
    dateJour(){
      if (this.dateJour) {
        this.launchRequest()
      }
    },
    detteFournisseur(){
      if (this.detteFournisseur) {
        this.dette=Math.round(Number(this.detteFournisseur.dette_fournisseur))
        if (this.dette > this.total.travaux.situation_a_date) {
          this.coloresult="bad-news"
        }else{
          this.coloresult="good-news"
        }
        this.loaded=true
      }
    }
  },
  created(){
    // var jour = new Date()
    // this.dateJour = jour.getFullYear()+"-"
    // if (jour.getMonth() < 9) {
    //   this.dateJour +="0"
    // }
    // this.dateJour+=(jour.getMonth()+1)+"-"
    // if (jour.getDate() < 10) {
    //   this.dateJour +="0" 
    // }
    // this.dateJour+=jour.getDate()
    this.loadTresorerieSiege({date:this.dateJour,base:this.extBase})
  },
  computed:{
    ...mapGetters(["tresorerieSiege","msgFailTresorerieSiege","detteFournisseur","msgFailDetteFournisseur"])
  },
  methods:{
    ...mapActions(["loadTresorerieSiege","loadDetteFournisseur"]),
    ...mapMutations(["setTresorerieSiege","setMsgFailTresorerieSiege","setDetteFournisseur","setMsgFailDetteFournisseur"]),
    launchRequest(){
      this.loadTresorerieSiege({date:this.dateJour,base:this.extBase})
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
})
</script>
