<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-2 mx-auto">
        <form class="form form-inline">
          <div class="form-group">
            <label for="">Date:</label>
            <input type="date" 
                   class="form-control ml-2"
                   v-model="date_jour"
                   @change="launchRequest()"/>
          </div>
        </form>
      </div>
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  :filename="'Situation_de_trésorerie_journalière_concession_'+date_jour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-3">
          <div class="col-3 ml-4">
            <img src="../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ date_jour }}
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Situation de trésorerie journalière (CONCESSION)
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <table class="table tablestyle_3 table-striped my-4">
            <thead>
              <tr>
                <th class="tht-blue">Intitulé du compte (FER)</th>
                <th class="tht-blue text-right">Situation antérieure</th>
                <th class="tht-blue text-right">Mouvements entrants</th>
                <th class="tht-blue text-right">Mouvements sortants</th>
                <th class="tht-blue text-right">Situation à date</th>
                <th class="tht-blue">Observations</th>
              </tr>
            </thead>
            <!-- COMPTES TRAVAUX -->
            <tbody>
              <template v-for="(concession,cokey) in tresorerieConcession.donnees">
                <tr :key="cokey"
                    v-if="concession.groupe=='COMPTES_TRAVAUX_PEAGE_PESAGE'">  
                  <td scope="row">{{ concession.intitule_compte }}</td>
                  <td class="text-right">{{ concession.situation_anterieure.toLocaleString() }}</td>
                  <td class="text-right">{{concession.mouvements_entrants.toLocaleString()}}</td>
                  <td class="text-right">{{concession.mouvements_sortants.toLocaleString()}}</td>
                  <td class="text-right">{{ concession.situation_a_date.toLocaleString() }}</td>
                  <td>{{ concession.observation }}</td>
                </tr>
              </template>
            </tbody>
            <tbody>
              <tr>
                <td scope="row"
                    class="tht-dark-blue">COMPTES TRAVAUX PEAGE/PESAGE</td>
                <td class="tht-dark-blue text-right">{{ total.travaux.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.travaux.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.travaux.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.travaux.situation_a_date.toLocaleString() }}</td>
                <td class="tht-dark-blue"></td>
              </tr>
            </tbody>      

            <!-- COMPTES FONCTIONNEMENT & INVESTISSEMENTS PEAGE/PESAGE -->
            <tbody>
              <template v-for="(concession,cokey) in tresorerieConcession.donnees">
                <tr :key="cokey"
                    v-if="concession.groupe=='COMPTES_FONCTIONNEMENT_INVESTISSEMENTS_PEAGE_PESAGE'">  
                  <td scope="row">{{ concession.intitule_compte }}</td>
                  <td class="text-right">{{ concession.situation_anterieure.toLocaleString() }}</td>
                  <td class="text-right">{{concession.mouvements_entrants.toLocaleString()}}</td>
                  <td class="text-right">{{concession.mouvements_sortants.toLocaleString()}}</td>
                  <td class="text-right">{{ concession.situation_a_date.toLocaleString() }}</td>
                  <td>{{ concession.observation }}</td>
                </tr>
              </template>
            </tbody>
            <tbody>
              <tr>
                <td scope="row"
                    class="tht-dark-blue">COMPTES FONCTIONNEMENT & INVESTISSEMENTS PEAGE/PESAGE</td>
                <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.situation_a_date.toLocaleString() }}</td>
                <td class="tht-dark-blue"></td>
              </tr>
            </tbody>

            <!-- COMPTES RECETTES PEAGE & SERVICE DETTES  -->
            <tbody>
              <template v-for="(concession,cokey) in tresorerieConcession.donnees">
                <tr :key="cokey"
                    v-if="concession.groupe=='COMPTES_RECETTES_PEAGE_SERVICE_DETTE'">  
                  <td scope="row">{{ concession.intitule_compte }}</td>
                  <td class="text-right">{{ concession.situation_anterieure.toLocaleString() }}</td>
                  <td class="text-right">{{concession.mouvements_entrants.toLocaleString()}}</td>
                  <td class="text-right">{{concession.mouvements_sortants.toLocaleString()}}</td>
                  <td class="text-right">{{ concession.situation_a_date.toLocaleString() }}</td>
                  <td>{{ concession.observation }}</td>
                </tr>
              </template>
            </tbody>
            <tbody>
              <tr>
                <td scope="row"
                    class="tht-dark-blue">COMPTES RECETTES PEAGE & SERVICE DETTES</td>
                <td class="tht-dark-blue text-right">{{ total.recette_service.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.recette_service.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.recette_service.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.recette_service.situation_a_date.toLocaleString() }}</td>
                <td class="tht-dark-blue"></td>
              </tr>
            </tbody>

            <!-- AUTRES  --> 
            <tbody>
              <template v-for="(concession,cokey) in tresorerieConcession.donnees">
                <tr :key="cokey"
                    v-if="concession.groupe=='AUTRES'">  
                  <td scope="row">{{ concession.intitule_compte }}</td>
                  <td class="text-right">{{ concession.situation_anterieure.toLocaleString() }}</td>
                  <td class="text-right">{{concession.mouvements_entrants.toLocaleString()}}</td>
                  <td class="text-right">{{concession.mouvements_sortants.toLocaleString()}}</td>
                  <td class="text-right">{{ concession.situation_a_date.toLocaleString() }}</td>
                  <td>{{ concession.observation }}</td>
                </tr>
              </template>
            </tbody>
            <tbody>
              <tr>
                <td scope="row"
                    class="tht-dark-blue">AUTRES</td>
                <td class="tht-dark-blue text-right">{{ total.autre.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.autre.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.autre.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-dark-blue text-right">{{ total.autre.situation_a_date.toLocaleString() }}</td>
                <td class="tht-dark-blue"></td>
              </tr>
            </tbody>
            <!-- TOTAL -->
            <tbody>
              <tr>
                <td scope="row"
                    class="tht-blue">TOTAL</td>
                <td class="tht-blue text-right">{{ total.total.situation_anterieure.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.total.mouvements_entrants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.total.mouvements_sortants.toLocaleString() }}</td>
                <td class="tht-blue text-right">{{ total.total.situation_a_date.toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <table class="table tablestyle_3 table-striped my-4">
      <thead>
        <tr>
          <th class="tht-blue">Intitulé du compte (FER)</th>
          <th class="tht-blue text-right">Situation antérieure</th>
          <th class="tht-blue text-right">Mouvements entrants</th>
          <th class="tht-blue text-right">Mouvements sortants</th>
          <th class="tht-blue"></th>
          <th class="tht-blue text-right">Situation à date</th>
          <th class="tht-blue">Observations</th>
        </tr>
      </thead>
      <!-- COMPTES TRAVAUX -->
      <tbody>
        <template v-for="(concession,cokey) in tresorerieConcession.donnees">
          <tr :key="cokey"
              v-if="concession.groupe=='COMPTES_TRAVAUX_PEAGE_PESAGE'">  
            <td scope="row">{{ concession.intitule_compte }}</td>
            <td class="text-right">{{ concession.situation_anterieure.toLocaleString() }}</td>
            <td class="text-right">{{concession.mouvements_entrants.toLocaleString()}}</td>
            <td class="text-right">{{concession.mouvements_sortants.toLocaleString()}}</td>
            <!-- <td>
              <input type="text"
                     v-if="form.groupe=='AUTRES'"
                     v-model="form.mouvement_entrant"/></td>
            <td><input type="text"
                       v-if="form.groupe=='AUTRES'"
                       v-model="form.mouvement_sortant"/></td>-->
            <td> 
              <button class="btn btn-blue btn-add"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#modal"
                      @click="form.id=concession.id_compte_bancaire,form.compte_bancaire=concession.id_compte_bancaire,form.groupe=concession.groupe">Ajouter</button></td>
            <td class="text-right">{{ concession.situation_a_date.toLocaleString() }}</td>
            <td>{{ concession.observation }}</td>
          </tr>
        </template>
      </tbody>
      <tbody>
        <tr>
          <td scope="row"
              class="tht-dark-blue">COMPTES TRAVAUX PEAGE/PESAGE</td>
          <td class="tht-dark-blue text-right">{{ total.travaux.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.travaux.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.travaux.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
          <td class="tht-dark-blue text-right">{{ total.travaux.situation_a_date.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
        </tr>
      </tbody>

      <!-- COMPTES FONCTIONNEMENT & INVESTISSEMENTS PEAGE/PESAGE -->
      <tbody>
        <template v-for="(concession,cokey) in tresorerieConcession.donnees">
          <tr :key="cokey"
              v-if="concession.groupe=='COMPTES_FONCTIONNEMENT_INVESTISSEMENTS_PEAGE_PESAGE'">  
            <td scope="row">{{ concession.intitule_compte }}</td>
            <td class="text-right">{{ concession.situation_anterieure.toLocaleString() }}</td>
            <td class="text-right">{{concession.mouvements_entrants.toLocaleString()}}</td>
            <td class="text-right">{{concession.mouvements_sortants.toLocaleString()}}</td>
            <!-- <td>
              <input type="text"
                     v-if="form.groupe=='AUTRES'"
                     v-model="form.mouvement_entrant"/></td>
            <td><input type="text"
                       v-if="form.groupe=='AUTRES'"
                       v-model="form.mouvement_sortant"/></td>-->
            <td> 
              <button class="btn btn-blue btn-add"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#modal"
                      @click="form.id=concession.id_compte_bancaire,form.compte_bancaire=concession.id_compte_bancaire,form.groupe=concession.groupe">Ajouter</button></td>
            <td class="text-right">{{ concession.situation_a_date.toLocaleString() }}</td>
            <td>{{ concession.observation }}</td>
          </tr>
        </template>
      </tbody>
      <tbody>
        <tr>
          <td scope="row"
              class="tht-dark-blue">COMPTES FONCTIONNEMENT & INVESTISSEMENTS PEAGE/PESAGE</td>
          <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
          <td class="tht-dark-blue text-right">{{ total.fonctionnement_investissements.situation_a_date.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
        </tr>
      </tbody>
      
      <!-- COMPTES RECETTES PEAGE & SERVICE DETTES  -->
      <tbody>
        <template v-for="(concession,cokey) in tresorerieConcession.donnees">
          <tr :key="cokey"
              v-if="concession.groupe=='COMPTES_RECETTES_PEAGE_SERVICE_DETTE'">  
            <td scope="row">{{ concession.intitule_compte }}</td>
            <td class="text-right">{{ concession.situation_anterieure.toLocaleString() }}</td>
            <td class="text-right">{{concession.mouvements_entrants.toLocaleString()}}</td>
            <td class="text-right">{{concession.mouvements_sortants.toLocaleString()}}</td>
            <!-- <td>
              <input type="text"
                     v-if="form.groupe=='AUTRES'"
                     v-model="form.mouvement_entrant"/></td>
            <td><input type="text"
                       v-if="form.groupe=='AUTRES'"
                       v-model="form.mouvement_sortant"/></td>-->
            <td> 
              <button class="btn btn-blue btn-add"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#modal"
                      @click="form.id=concession.id_compte_bancaire,form.compte_bancaire=concession.id_compte_bancaire,form.groupe=concession.groupe">Ajouter</button></td>
            <td class="text-right">{{ concession.situation_a_date.toLocaleString() }}</td>
            <td>{{ concession.observation }}</td>
          </tr>
        </template>
      </tbody>
      <tbody>
        <tr>
          <td scope="row"
              class="tht-dark-blue">COMPTES RECETTES PEAGE & SERVICE DETTES</td>
          <td class="tht-dark-blue text-right">{{ total.recette_service.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.recette_service.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.recette_service.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
          <td class="tht-dark-blue text-right">{{ total.recette_service.situation_a_date.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
        </tr>
      </tbody>
      
      <!-- AUTRES  --> 
      <tbody>
        <template v-for="(concession,cokey) in tresorerieConcession.donnees">
          <tr :key="cokey"
              v-if="concession.groupe=='AUTRES'">  
            <td scope="row">{{ concession.intitule_compte }}</td>
            <td class="text-right">{{ concession.situation_anterieure.toLocaleString() }}</td>
            <td class="text-right">{{concession.mouvements_entrants.toLocaleString()}}</td>
            <td class="text-right">{{concession.mouvements_sortants.toLocaleString()}}</td>
            <!-- <td>
              <input type="text"
                     v-if="form.groupe=='AUTRES'"
                     v-model="form.mouvement_entrant"/></td>
            <td><input type="text"
                       v-if="form.groupe=='AUTRES'"
                       v-model="form.mouvement_sortant"/></td>-->
            <td> 
              <button class="btn btn-blue btn-add"
                      data-toggle="modal"
                      ref="modal_button"
                      data-target="#modal"
                      @click="form.id=concession.id_compte_bancaire,form.compte_bancaire=concession.id_compte_bancaire,form.groupe=concession.groupe">Ajouter</button></td>
            <td class="text-right">{{ concession.situation_a_date.toLocaleString() }}</td>
            <td>{{ concession.observation }}</td>
          </tr>
        </template>
      </tbody>
      <tbody>
        <tr>
          <td scope="row"
              class="tht-dark-blue">AUTRES</td>
          <td class="tht-dark-blue text-right">{{ total.autre.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.autre.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-dark-blue text-right">{{ total.autre.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
          <td class="tht-dark-blue text-right">{{ total.autre.situation_a_date.toLocaleString() }}</td>
          <td class="tht-dark-blue"></td>
        </tr>
      </tbody>
      <!-- TOTAL -->
      <tbody>
        <tr>
          <td scope="row"
              class="tht-blue">TOTAL</td>
          <td class="tht-blue text-right">{{ total.total.situation_anterieure.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.total.mouvements_entrants.toLocaleString() }}</td>
          <td class="tht-blue text-right">{{ total.total.mouvements_sortants.toLocaleString() }}</td>
          <td class="tht-blue"></td>
          <td class="tht-blue text-right">{{ total.total.situation_a_date.toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="modal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="modalLabel"
         aria-hidden="true">
      <div class="modal-dialog "
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="modalLabel">Ajouter un mouvement</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Mouvement entrant</label>
                <input type="text"
                       class="form-control"
                       v-model="form.mouvement_entrant"/>
              </div>
              <div class="form-group">
                <label for="">Mouvement sortant</label>
                <input type="text"
                       class="form-control"
                       v-model="form.mouvement_sortant"/>
              </div>
              <div class="form-group">
                <label for="">Observation</label>
                <textarea name="" 
                          id="" 
                          cols="30" 
                          rows="3"
                          class="form-control"
                          v-model="form.observation"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="EnvoyerMouvement(form)">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import VueHtml2pdf from 'vue-html2pdf'

export default ({
  name:"Concession",
  props:[],
  components: {
    Notif,
    VueHtml2pdf
  },
  data: ()=>({
    date_jour: "",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form:{
      id:"",
      source:"",
      groupe:"",
      compte_bancaire:"", //siege.compte_bancaire,
      mouvement_entrant:"",
      mouvement_sortant:"",
      observation:"",
      date:""
    },
    loaded:false,
    total:{
      travaux:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      fonctionnement_investissements:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      recette_service:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      autre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
      total:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
    },
  }),
  watch:{
    tresorerieConcession(){
      if (this.tresorerieConcession) {
        this.form={
          id:"",
          source:"",
          groupe:"",
          compte_bancaire:"", //siege.compte_bancaire,
          mouvement_entrant:"",
          mouvement_sortant:"",
          observation:"",
          date:this.date_jour}

        this.total={
          travaux:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          fonctionnement_investissements:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          recette_service:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          autre:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
          total:{situation_anterieure:0,mouvements_entrants:0,mouvements_sortants:0,situation_a_date:0},
        }
        
        // Création des formualaires par ligne
        this.tresorerieConcession.donnees.forEach(concession => {
          // calcul des totaux
          if (concession.groupe=='COMPTES_TRAVAUX_PEAGE_PESAGE') {
            this.total.travaux.situation_anterieure+=concession.situation_anterieure
            this.total.travaux.mouvements_entrants+=concession.mouvements_entrants
            this.total.travaux.mouvements_sortants+=concession.mouvements_sortants
            this.total.travaux.situation_a_date+=concession.situation_a_date

            this.total.total.situation_anterieure+=concession.situation_anterieure
            this.total.total.mouvements_entrants+=concession.mouvements_entrants
            this.total.total.mouvements_sortants+=concession.mouvements_sortants
            this.total.total.situation_a_date+=concession.situation_a_date
          }
          if (concession.groupe=='COMPTES_FONCTIONNEMENT_INVESTISSEMENTS_PEAGE_PESAGE') {
            this.total.fonctionnement_investissements.situation_anterieure+=concession.situation_anterieure
            this.total.fonctionnement_investissements.mouvements_entrants+=concession.mouvements_entrants
            this.total.fonctionnement_investissements.mouvements_sortants+=concession.mouvements_sortants
            this.total.fonctionnement_investissements.situation_a_date+=concession.situation_a_date

            this.total.total.situation_anterieure+=concession.situation_anterieure
            this.total.total.mouvements_entrants+=concession.mouvements_entrants
            this.total.total.mouvements_sortants+=concession.mouvements_sortants
            this.total.total.situation_a_date+=concession.situation_a_date
          }
          if (concession.groupe=='COMPTES_RECETTES_PEAGE_SERVICE_DETTE') {
            this.total.recette_service.situation_anterieure+=concession.situation_anterieure
            this.total.recette_service.mouvements_entrants+=concession.mouvements_entrants
            this.total.recette_service.mouvements_sortants+=concession.mouvements_sortants
            this.total.recette_service.situation_a_date+=concession.situation_a_date

            this.total.total.situation_anterieure+=concession.situation_anterieure
            this.total.total.mouvements_entrants+=concession.mouvements_entrants
            this.total.total.mouvements_sortants+=concession.mouvements_sortants
            this.total.total.situation_a_date+=concession.situation_a_date
          }
          if (concession.groupe=='AUTRES') {
            this.total.autre.situation_anterieure+=concession.situation_anterieure
            this.total.autre.mouvements_entrants+=concession.mouvements_entrants
            this.total.autre.mouvements_sortants+=concession.mouvements_sortants
            this.total.autre.situation_a_date+=concession.situation_a_date

            this.total.total.situation_anterieure+=concession.situation_anterieure
            this.total.total.mouvements_entrants+=concession.mouvements_entrants
            this.total.total.mouvements_sortants+=concession.mouvements_sortants
            this.total.total.situation_a_date+=concession.situation_a_date
          }
        })
        this.loaded=true
        console.log(this.total)
      }
    },
    mouvement(){
      if (this.mouvement) {
        this.setTresorerieConcession("")
        this.notif.message = "Mouvement enregistré"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.form={
              id:"",
              source:"",
              groupe:"",
              compte_bancaire:"", //siege.compte_bancaire,
              mouvement_entrant:"",
              mouvement_sortant:"",
              observation:"",
              date:this.date_jour}
            this.$refs["btn-close"].click()
            this.setMouvement("")
            this.loadTresorerieConcession(this.date_jour)
          }.bind(this),
          3000
        )
      }
    }
  },
  created(){
    var jour = new Date()
    this.date_jour = jour.getFullYear()+"-"
    if (jour.getMonth() < 9) {
      this.date_jour +="0"
    }
    this.date_jour+=(jour.getMonth()+1)+"-"
    if (jour.getDate() < 10) {
      this.date_jour +="0" 
    }
    this.date_jour+=jour.getDate()
    this.loadTresorerieConcession(this.date_jour)
  },
  computed:{
    ...mapGetters(["tresorerieConcession","msgFailTresorerieConcession","mouvement","msgFailMouvement"])
  },
  methods:{
    ...mapActions(["loadTresorerieConcession","sendMouvement"]),
    ...mapMutations(["setTresorerieConcession","setMsgFailTresorerieConcession","setMouvement","setMsgFailMouvement"]),
    launchRequest(){
      this.loadTresorerieConcession(this.date_jour)
    },
    EnvoyerMouvement(form){
      if (form.observation == null) {
        form.observation = ""
      }
      if (form.mouvement_entrant=="" || form.mouvement_entrant==null) {
        form.mouvement_entrant=0
      }
      if (form.mouvement_sortant=="" || form.mouvement_sortant==null) {
        form.mouvement_sortant=0
      }
      console.log(form)
      this.sendMouvement(form)
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
})
</script>
